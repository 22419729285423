import { Modal, Button, CloseButton } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { free, restart, contact_mail, contact_us } from "../../Image/Index";
import { modalCard, startTour, countFirst } from "../../actions/intro";
import { useNavigate } from "react-router-dom";
import { decrypt } from "../../utils/encodingdecoding";

const ModalCard = (props) => {
    const [show, setShow] = useState(false);
    let [count, setCount] = useState(0);
    const [customer, setCustomer] = useState(false);
    const [plan, setPlan] = useState({});
    const [nextPackage, setNextPackage] = useState();
    const navigate = useNavigate();
    const currentUrl = window.location.href;
    const counts = useSelector((state) => state.intro.countFirst);
    const paymet = useSelector((state) => state.intro.paymentMoadal);
    const dispatch = useDispatch();
    let packageNameList = localStorage.getItem("packageNameList") && JSON.parse(decrypt(localStorage.getItem("packageNameList")));
    useEffect(() => {
        if (props.show) {
            setShow(true);
        }
        if (props?.customer && props?.customer) setCustomer(true);
    }, [props.show]);
    useEffect(() => {
        if (paymet) {
            setShow(false);
        }
    }, [paymet]);
    const endTour = () => {
        setShow(false);
        let cardStatus = localStorage.getItem("cardStatus") && decrypt(localStorage.getItem("cardStatus"));
        localStorage.setItem("firstLogin", "");
        cardStatus === "Active" ? (window.location.href = "/dashboard") : (window.location.href = "/profile-settings?p03");
    };
    const startTours = () => {
        setShow(false);
        localStorage.setItem("firstLogin", "Y");
    };

    useEffect(() => {
        packageNameList.sort(function (a, b) {
            return parseFloat(a.id) - parseFloat(b.id);
        });
        let cardStatus = localStorage.getItem("cardStatus") && decrypt(localStorage.getItem("cardStatus"));
        if (cardStatus) {
            let currentPackage = localStorage.getItem("plan") && Number(decrypt(localStorage.getItem("plan")));
            const len = packageNameList.length;
            for (let i = 0; i < len; i++) {
                if (currentPackage && currentPackage == packageNameList[i].id) {
                    currentPackage = packageNameList[i].id;
                }
            }
            let tempNextPackage = packageNameList.find((o) => o.id == currentPackage + 1);
            if (tempNextPackage) {
                setPlan(tempNextPackage);
                setNextPackage(true);
            } else {
                setNextPackage(false);
            }
        } else {
            setPlan(packageNameList[0]);
        }
    }, []);

    const handleShow = () => {};
    return (
        <Fragment>
            {/* <Button  onClick={handleShow} >
      </Button> */}
            <Modal show={show} size="md" onHide={endTour} backdrop={true}>
                <Modal.Header className="custom_header" closeButton></Modal.Header>
                <Modal.Body className="dashboard__transactionsModal">
                    <h3 className="guides">{localStorage.getItem("cardStatus") && decrypt(localStorage.getItem("cardStatus")) === "Active" ? "Leaving Already?" : "Leaving so Soon?"}</h3>
                    {localStorage.getItem("cardStatus") && decrypt(localStorage.getItem("cardStatus")) === "Active" ? (
                        <>
                            <h5>Found What You're Looking For? </h5>
                            <p>Hey there! It looks like you're stepping away from the program journey. Have you found what you were looking for, or do you need assistance? </p>
                        </>
                    ) : (
                        <span>
                            <p>
                                We noticed you're exiting the journey, have questions or need assistance? We are here to help! Click <b>"Contact Us"</b> to get in touch.
                            </p>
                            {/* <p>
                                Curious about what you might be missing? Click <b>"Start Free Trial"</b> to experience our platform
                            </p> */}
                        </span>
                    )}
                    {/* <div className={nextPackage ? "tour_mainbox" : "tour_mainbox tour_mainbox2"}> */}
                    <div className="tour_mainbox tour_mainbox2">
                        {/* {localStorage.getItem("cardStatus") && decrypt(localStorage.getItem("cardStatus")) === "Active" ? (
                            nextPackage === true && (
                                <div className="tour_box">
                                    <Link to={plan.payLink + "&prefilled_email=" + decrypt(localStorage.getItem("email"))} target="_blank">
                                        <figure>
                                            <img src={free} alt="start for free" />
                                        </figure>
                                        <p>Upgrade Your Plan</p>
                                    </Link>
                                </div>
                            )
                        ) : (
                            <div className="tour_box">
                                <Link to={plan.payLink + "&prefilled_email=" + decrypt(localStorage.getItem("email"))} target="_blank">
                                    <figure>
                                        <img src={free} alt="start for free" />
                                    </figure>
                                    <p>Start Free Trial</p>
                                </Link>
                            </div>
                        )} */}
                        <div className="tour_box">
                            <Link
                                onClick={() => {
                                    window.location.href = "/contact-us";
                                }}
                            >
                                <figure>
                                    <img src={contact_mail} alt="contact us" />
                                </figure>
                                <p>Contact Us</p>
                            </Link>
                        </div>
                    </div>
                    {/* <div className="start_tour_btn"> */}
                    {/* <Button className="start_tour" onClick={endTour}> */}
                    {/* Leave */}
                    {/* </Button> */}
                    {/* </div> */}
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};
export default ModalCard;
