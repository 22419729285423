import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

const BarChartAnalyticalReportEmployee2 = ({ data }) => {
    const [chartData, setChartData] = useState({
        labels: ["18-20", "21-30", "31-40", "41-50", "51-60", "61-70", "71-80", "81-90"],
        datasets: [
            {
                label: "Recognition  Count",
                data: Array(8).fill(0),  
                fill: true,
                tension: 0.4,
                backgroundColor: "#2d8edb",
            },
        ],
    });

    useEffect(() => {
        if (data && typeof data === "object") {
            const labels = Object.keys(data); 
            const values = Object.values(data);
            const transformedData = values.map(value => (Number(value) / 100).toFixed(0));
            setChartData((prevData) => ({
                ...prevData,
                labels: labels,
                datasets: [
                    {
                        ...prevData.datasets[0],
                        data: transformedData, 
                    },
                ],
            }));
        }
    }, [data]);

    const options = {
        indexAxis: "x",
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div className="analyticalReportChartAnalysisTwo__barChartReport">
            <Bar data={chartData} options={options} />
        </div>
    );
};

export default BarChartAnalyticalReportEmployee2;
