import React, { memo, useEffect, useRef, useState } from "react";
import Header from "../../layout/Header";
import { Link, useNavigate } from "react-router-dom";
import { decrypt } from "../../../utils/encodingdecoding";
import { getUserJourneyReport } from "../../../actions/dashboard";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { connect } from "react-redux";
import { clearCampaigns } from "../../../actions/campaigns";
import { getBusinessGoal, clearBusinessGoal } from "../../../actions/businessGoal";

const AnalyticalReport = memo(({ clearCampaigns, getBusinessGoal, clearBusinessGoal, BusinessGoal }) => {
    const navigate = useNavigate();
    const [FormData, setFormData] = useState({});
    const [heading, setHeading] = useState("");
    const [firstRender, setFirstRender] = useState(true);
    const [loader, setLoader] = useState(false);
    const tooltip = {
        cannotLoseThem: `Customer who were doing transaction till last month but haven't done anything from last 30 days.`,
        loyalCustomers: `Customer who is doing transaction every month.`,
        champions: `Customer who higest RFM along with every month.`,
        atRisk: `Customer who is not doing transacation since last 3 months.`,
        needAttention: `Customer who is doing low transaction.`,
        potentialLoyalists: `Customer who has High to low transaction amount.`,
        hibernating: `No transaction in last 1 year.`,
        aboutToSleep: `Who has 0 transaction in last 6 months.`,
        promising: `Similar amount transaction in every month.`,
        newCustomers: `Customer who has done Transaction in last 3 months but never did before.`,
    };
    const [state, setState] = useState({
        cannotLoseThem: false,
        loyalCustomers: false,
        champions: false,
        atRisk: false,
        needAttention: false,
        potentialLoyalists: false,
        hibernating: false,
        aboutToSleep: false,
        promising: false,
        newCustomers: false,
        businessGoal: [],
        goals: [],
    });
    const { cannotLoseThem, loyalCustomers, champions, atRisk, needAttention, potentialLoyalists, hibernating, aboutToSleep, promising, newCustomers, businessGoal, goals } = state;
    const targetRef = useRef(null);

    const fetch = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const Id = localStorage.getItem("Id");
        await getUserJourneyReport(token?.access_token, Id).then((res) => {
            setFormData(res);
            setHeading(res[0]?.datastatus);
        }).catch((err)=>{
            console.log(err.message)
        });
        setFirstRender(false);
        setLoader(false);
    };

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {
        fetchBusinessGoal();
    }, []);

    useEffect(() => {
        setState({ ...state, businessGoal: BusinessGoal });
    }, [BusinessGoal]);

    useEffect(() => {
        const industry = decrypt(localStorage.getItem("schemaName"));
        businessGoal &&
            businessGoal.map((item) => {
                if (item.industry === industry) {
                    setState({ ...state, goals: item.goals });
                }
            });
    }, [businessGoal]);

    const fetchBusinessGoal = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        await clearBusinessGoal();
        await getBusinessGoal(token?.access_token);
        setLoader(false);
    };

    const handleSkipClick = async () => {
        await clearCampaigns();
        navigate("/sample-campaigns", { state: [] });
    };

    return (
        <>
            <Header name={`${heading ? heading : ""} Analytical Report`} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="analyticalReport">
                <div className="analyticalReportRFMAnalysis">
                    <div className="analyticalReportRFMAnalysis__topSection analyticalReportRFMAnalysis__topSection__left">
                        <div className="container">
                            <div className="analyticalReportRFMAnalysis__rightSection">
                                <h3>
                                    {/* <sup>1</sup> */}
                                    Getting Started
                                </h3>
                                <p>Here are some reports to help you get started and understand the points where you can improve your performance in order to increase sales. </p>
                            </div>
                        </div>
                    </div>
                    <div className="analyticalReportRFMAnalysis__bottomSection">
                        <div className="container">
                            <div className="analyticalReportRFMAnalysis__paragraph">
                                <p>
                                    <strong>RFM Analysis is one of the most exciting parts of the dashboard</strong>. It presents the latest customer activity or purchase, the frequency of customer transactions or visits, and the purchasing power of a customer. Interpret the RFM segments to understand customer behaviors and reward them accordingly. 
                                </p>
                                <p>
                                Upload your existing data for reports. The data should be at least six months old from the current date.{" "}
                                    <Link to="/uploading-existing-datas" state={{ from: "upload" }}>
                                        Click here to upload data
                                    </Link>
                                </p>
                            </div>
                            {/* <div className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box">
                                <div className="analyticalReportRFMAnalysis__details">
                                    <h1>Total Customers {FormData?.TotalCustomer}</h1>
                                </div>
                            </div> */}
                            <div className="analyticalReportRFMAnalysis__content">
                                <div className="analyticalReportRFMAnalysis__yAxis">
                                    <span className="label">Frequency Score</span>
                                    <ul>
                                        <li className="legend">1</li>
                                        <li className="legend">2</li>
                                        <li className="legend">3</li>
                                        <li className="legend">4</li>
                                        <li className="legend">5</li>
                                    </ul>
                                </div>
                                <div className="analyticalReportRFMAnalysis__boxes">
                                    <div className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--first">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="cannotLoseThem"
                                                onClick={() => {
                                                    setState({ cannotLoseThem: !cannotLoseThem });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ cannotLoseThem: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("cannotLoseThem")} rootClose={true} onHide={() => setState({ cannotLoseThem: false })} show={cannotLoseThem} placement="bottom">
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.cannotLoseThem}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                Can't Lose Them <br />
                                                {firstRender ? "" : FormData[0]?.CanNotLoseThem}
                                                {firstRender ? "" : ` (${FormData[0]?.CanNotLoseThem ? ((FormData[0]?.CanNotLoseThem / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--second">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="loyalCustomers"
                                                onClick={() => {
                                                    setState({ loyalCustomers: !loyalCustomers });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ loyalCustomers: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("loyalCustomers")} show={loyalCustomers} placement="bottom" rootClose={true} onHide={() => setState({ loyalCustomers: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.loyalCustomers}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                Loyal Customers <br />
                                                {firstRender ? "" : FormData[0]?.LoyalCustomer}
                                                {firstRender ? "" : ` (${FormData[0]?.LoyalCustomer ? ((FormData[0]?.LoyalCustomer / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--third">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="champions"
                                                onClick={() => {
                                                    setState({ champions: !champions });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ champions: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("champions")} show={champions} placement="bottom" rootClose={true} onHide={() => setState({ champions: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.champions}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                Champions <br />
                                                {firstRender ? "" : FormData[0]?.Champions}
                                                {firstRender ? "" : ` (${FormData[0]?.Champions ? ((FormData[0]?.Champions / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--fourth">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="atRisk"
                                                onClick={() => {
                                                    setState({ atRisk: !atRisk });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ atRisk: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("atRisk")} show={atRisk} placement="bottom" rootClose={true} onHide={() => setState({ atRisk: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.atRisk}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                At Risk <br />
                                                {firstRender ? "" : FormData[0]?.AtRisk}
                                                {firstRender ? "" : ` (${FormData[0]?.AtRisk ? ((FormData[0]?.AtRisk / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--fifth">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="needAttention"
                                                onClick={() => {
                                                    setState({ needAttention: !needAttention });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ needAttention: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("needAttention")} show={needAttention} placement="bottom" rootClose={true} onHide={() => setState({ needAttention: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.needAttention}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                Need Attention <br />
                                                {firstRender ? "" : FormData[0]?.Needattention}
                                                {firstRender ? "" : ` (${FormData[0]?.Needattention ? ((FormData[0]?.Needattention / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--sixth">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="potentialLoyalists"
                                                onClick={() => {
                                                    setState({ potentialLoyalists: !potentialLoyalists });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ potentialLoyalists: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("potentialLoyalists")} show={potentialLoyalists} placement="bottom" rootClose={true} onHide={() => setState({ potentialLoyalists: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.potentialLoyalists}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                Potential Loyalists <br />
                                                {firstRender ? "" : FormData[0]?.PotentialLoyalist}
                                                {firstRender ? "" : ` (${FormData[0]?.PotentialLoyalist ? ((FormData[0]?.PotentialLoyalist / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--seventh">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="hibernating"
                                                onClick={() => {
                                                    setState({ hibernating: !hibernating });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ hibernating: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("hibernating")} show={hibernating} placement="bottom" rootClose={true} onHide={() => setState({ hibernating: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.hibernating}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                Hibernating <br />
                                                {firstRender ? "" : FormData[0]?.Hibernation}
                                                {firstRender ? "" : ` (${FormData[0]?.Hibernation ? ((FormData[0]?.Hibernation / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--eighth">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="aboutToSleep"
                                                onClick={() => {
                                                    setState({ aboutToSleep: !aboutToSleep });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ aboutToSleep: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("aboutToSleep")} show={aboutToSleep} placement="bottom" rootClose={true} onHide={() => setState({ aboutToSleep: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.aboutToSleep}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                About To Sleep <br />
                                                {firstRender ? "" : FormData[0]?.AboutToSleep}
                                                {firstRender ? "" : ` (${FormData[0]?.AboutToSleep ? ((FormData[0]?.AboutToSleep / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--ninth">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="promising"
                                                onClick={() => {
                                                    setState({ promising: !promising });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ promising: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("promising")} show={promising} placement="bottom" rootClose={true} onHide={() => setState({ promising: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.promising}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                Promising <br />
                                                {firstRender ? "" : FormData[0]?.Promising}
                                                {firstRender ? "" : ` (${FormData[0]?.Promising ? ((FormData[0]?.Promising / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="analyticalReportRFMAnalysis__box analyticalReportRFMAnalysis__box--tenth">
                                        <div className="analyticalReportRFMAnalysis__details">
                                            <i
                                                className="arRFMa_tooltip"
                                                id="newCustomers"
                                                onClick={() => {
                                                    setState({ newCustomers: !newCustomers });
                                                }}
                                                ref={targetRef}
                                                onMouseLeave={() => {
                                                    setState({ newCustomers: false });
                                                }}
                                            >
                                                i
                                            </i>
                                            <Overlay target={document.getElementById("newCustomers")} show={newCustomers} placement="bottom" rootClose={true} onHide={() => setState({ newCustomers: false })}>
                                                {(props) => (
                                                    <Tooltip id="box1" {...props}>
                                                        {tooltip.newCustomers}
                                                    </Tooltip>
                                                )}
                                            </Overlay>
                                            <h3>
                                                New Customers <br />
                                                {firstRender ? "" : FormData[0]?.NewCustomer}
                                                {firstRender ? "" : ` (${FormData[0]?.NewCustomer ? ((FormData[0]?.NewCustomer / FormData[0]?.TotalCustomer) * 100).toFixed(2) : 0}) %`}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="analyticalReportRFMAnalysis__xAxis">
                                    <ul>
                                        <li className="legend">1</li>
                                        <li className="legend">2</li>
                                        <li className="legend">3</li>
                                        <li className="legend">4</li>
                                        <li className="legend">5</li>
                                    </ul>
                                    <span className="label">Recency Score</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="analyticalReportChartAnalysisTwo">
                    <div className="container">
                        <div className="analyticalReportChartAnalysisTwo__bottomSection analyticalReportChartAnalysisTwo__bottomSection_2">
                            <div className="analyticalReportChartAnalysisTwo__rightSection">
                                <div className="analyticalReportChartAnalysisTwo__paragraph">
                                    <p>With the help of AI and your existing data, use Novus Loyalty to set up campaigns that supercharge your growth.</p>
                                </div>
                                <div className="analyticalReportChartAnalysisTwo__buttonSection">
                                    <Link to="/business-goals-selection" state={{ prevPath: true }} className="btn btn-primary">
                                        Select Your Business Goals
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </Link>
                                    {/* <Link to="" className="btn btn-outline-primary">
                                        Contact Novus Support
                                        <i className="fa-solid fa-comment-dots"></i>
                                    </Link> */}
                                    <Link to="/sample-campaigns" className="btn btn-link" onClick={handleSkipClick}>
                                        Skip
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});
const mapStateToProps = (state) => ({ BusinessGoal: state.BusinessGoal.data });
const mapDispatchToProps = {
    clearCampaigns,
    getBusinessGoal,
    clearBusinessGoal,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticalReport);
