import React, { useState, useEffect, useRef } from "react";
import { csvFileIcon, downloadIcon, importIcon } from "../../../Image/Index";
import Header from "../../layout/Header";
import { Link } from "react-router-dom";
import { getOffer, deleteOffer, uploadOffer } from "../campaigns/services";
import { ValidatorForm } from "react-form-validator-core";
import SmsSetting from "../setting/SmsSetting";
import { OFFER_COLUMN } from "../setting/offerColumn";
import swal from "sweetalert";
import SampleFile from "../../../sample/offer/Offer.csv";
import EmailTemplateModal from "../setting/EmailTemplateModal";
import { getDate } from "../../../utils/helper";

const Offer = () => {
  const [loader, setLoader] = useState(false);
  const [offer, setOffer] = useState({
    file: "",
    error: false,
    offerList: [],
  });
  const [modal, setModal] = useState({
    isOpen: false,
    desc: "",
  });

  const offerRef = useRef();
  const formRef = useRef();

  const handleImage = (url) => {
    const width = window.screen.width / 1.5;
    const height = window.screen.height / 1.5;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    window.open(
      url,
      "",
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };

  const fetchOffer = async () => {
    setLoader(true);
    const merchcode = localStorage.getItem("merchantcode");
    let token = await JSON.parse(localStorage.getItem("token_gen"));
    const res = await getOffer(token?.access_token, merchcode);
    if (res.message === "Offer data fetched successfully.") {
      setOffer((prev) => ({
        ...prev,
        offerList: res.data,
      }));
      if (res?.data?.length > 0) {
        res.data.map((val) => {
          return (
            (val.startDate = getDate(val.startDate, "DD/MM/YYYY")),
            (val.endDate = getDate(val.endDate, "DD/MM/YYYY")),
            (val.status = val.status.toString()),
            (val.imageAction = (
              <Link onClick={() => handleImage(val.offerimage)}>
                <i
                  className="fa-solid fa-image"
                  style={{ fontSize: "18px" }}
                ></i>
              </Link>
            )),
            (val.delete = (
              <Link onClick={() => handleOfferDel(val.productid)}>
                <i
                  className="fa fa-times-circle"
                  style={{ fontSize: "18px" }}
                ></i>
              </Link>
            )),
            (val.action = (
              <Link
                className="edit_btn"
                onClick={() => handleViewModal(val.offerdescription)}
              >
                <div className="show_div">View</div>
              </Link>
            ))
          );
        });
      }
      setLoader(false);
    } else {
      setOffer((prev) => ({
        ...prev,
        offerList: [],
      }));
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchOffer();
  }, []);

  const handleFileOffer = (e) => {
    const filedata = e.target.files[0];
    setOffer((prev) => ({
      ...prev,
      file: filedata,
      error: false,
    }));
  };

  const handleViewModal = (desc) => {
    setModal((prev) => ({
      ...prev,
      isOpen: true,
      desc: desc,
    }));
  };

  const handleOfferDel = async (prodId) => {
    const merId = localStorage.getItem("merchantcode");
    let token = await JSON.parse(localStorage.getItem("token_gen"));
    const res = await deleteOffer(token?.access_token, merId, prodId);
    if (res.message === "Offer data deleted successfully.") {
      swal({
        position: "center",
        icon: "success",
        title: "Offer delete successfully",
        showConfirmButton: false,
        timer: 2000,
      });
      await fetchOffer();
      setLoader(false);
    } else {
      swal({
        position: "center",
        icon: "error",
        title: res.message,
        showConfirmButton: false,
        timer: 2000,
      });
      setLoader(false);
    }
  };

  const submitOffer = async () => {
    if (offer?.file) {
      const token = JSON.parse(localStorage.getItem("token_gen"));
      setLoader(true);
      const response = await uploadOffer(offer.file, token?.access_token);
      if (response.message === "ProductOffer data uploaded successfully.") {
        swal({
          position: "center",
          icon: "success",
          title: "Offer file uploaded successfully",
          showConfirmButton: false,
          timer: 10000,
        });

        await fetchOffer();
        setLoader(false);
      } else {
        if (response.status === 500) {
          swal({
            position: "center",
            icon: "error",
            title: "Invalid file format. Please follow the correct template.",
            showConfirmButton: false,
            timer: 10000,
          });
        } else {
          swal({
            position: "center",
            icon: "error",
            title: response.message,
            showConfirmButton: false,
            timer: 10000,
          });
        }

        setLoader(false);
      }
      offerRef.current.value = "";
      setOffer((prev) => ({
        ...prev,
        file: "",
      }));
    } else {
      setOffer((prev) => ({
        ...prev,
        error: true,
      }));
    }
  };

  return (
    <>
      <Header name={"Offer"} />
      {loader && (
        <div className="mainPreloaderMain">
          <div className="mainPreloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}

      <div className="dashboard__content">
        <div className="container-fluid">
          <div className="dashboard__transactionsCard">
            <div className="dashboard__header">
              <div className="dashboard__left">
                <h4>Offer Data</h4>
              </div>
              <div className="dashboard__right">
                <Link
                  to={SampleFile}
                  id="upload_transction"
                  download="OfferSampleFile"
                  target="_blank"
                  className="btn btn-default"
                >
                  Offer Sample &nbsp;
                  <img src={downloadIcon} alt="" />
                </Link>
              </div>
            </div>
            <div className="dashboard__body">
              <p>
              You can add data by uploading a CSV file containing the details.
              </p>
              <div className="dashboard__profileSettingsMain">
                <div className="dashboard__profileSettingsBox offerUpload">
                  <ValidatorForm
                    className="customForm"
                    ref={formRef}
                    onSubmit={(e) => submitOffer(e)}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                        <div className="form-group withIcon mb-0">
                          {/* <label>Upload Offer</label> */}
                          <div className="uploadingExistingData__uploadBoxUploadFile">
                            <form encType="multipart/form-data">
                              <input
                                className="form-control"
                                placeholder="Upload Offer"
                                ref={offerRef}
                                type="file"
                                accept=".csv,.xls,.xlsx"
                                onChange={handleFileOffer}
                              />
                              {offer?.error && (
                                <span style={{ color: "red" }}>
                                  {" "}
                                  File is required{" "}
                                </span>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                        <div className="button-section justify-content-start">
                          <button
                            type="submit"
                            className="btn btn-primary btn38"
                          >
                            Upload
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger btn38"
                            onClick={() => {
                              offerRef.current.value = "";
                              setOffer((prev) => ({
                                ...prev,
                                file: "",
                                error: false,
                              }));
                            }}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard__transactionsCard">
            <div className="dashboard__header">
              <div className="dashboard__left">
                <h4 id="transction_list">Offer List</h4>
              </div>
            </div>
            <div className="dashboard__body">
              <SmsSetting
                columns={OFFER_COLUMN}
                data={offer.offerList || []}
                pagination={true}
              />
            </div>
          </div>
        </div>
        {modal?.isOpen && (
          <EmailTemplateModal
            show={modal?.isOpen}
            close={() => setModal((prev) => ({ ...prev, isOpen: false }))}
            body={modal.desc}
            header={"Offer"}
          />
        )}
      </div>
    </>
  );
};

export default Offer;
