export const COLUMNS = [
    {
        Header: "Field Name",
        Footer: "Field Name",
        accessor: "fieldName",
    },
    {
        Header: "Is Ident",
        Footer: "IsIdent",
        accessor: "isident",
        // Cell: ({ value }) => {
        // 	return format(new Date(value), "dd/MM/yyyy");
        // },
    },
    {
        Header: "Is Mobile Field",
        Footer: "Is Mobile Field",
        accessor: "IsMobileField",
    },
    {
        Header: "Is Sign Up",
        Footer: "Is Sign Up",
        accessor: "isSignUp",
    },
    {
        Header: "Is Required",
        Footer: "Is Required",
        accessor: "isrequired",
    },
    {
        Header: "Action",
        Footer: "Action",
        accessor: "action",
    },
    // {
    //     Header: "Is Sign Up",
    //     Footer: "Is Sign Up",
    //     accessor: "isSignUp",
    // },
    // {
    //     Header: "Is Sign Up",
    //     Footer: "Is Sign Up",
    //     accessor: "isSignUp",
    // },
];
