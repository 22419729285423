export const COLUMNS = [
    {
        Header: "S. No.",
        Footer: "S. No.",
        accessor: "serialNumber",
    },
    {
        Header: "Employee Group Name",
        Footer: "Employee Group Name",
        accessor: "customergroupname",
        disableFilters: true,
        sticky: "left",
    },
    {
        Header: "Create Date",
        Footer: "Create Date",
        accessor: "createDate",
    },
   
    {
        Header: "Action",
        Footer: "Action",
        accessor: "action",
    },
];
