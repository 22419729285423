import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { COLUMNS } from "./column";
import Header from "../../layout/Header";
import Paginated from "./Paginated";
import { getSchemaList } from "../../../actions/auth";
import { schema } from "../campaigns/services";


function TransactionSchema() {
    const [formData, setFormData] = useState([]);
    const [response,setResponse] = useState([])

    const [loader, setLoader] = useState(false);
    const updateData = () => {
        setLoader(true)
        const token = JSON.parse(localStorage.getItem("token_gen"));
        let schemaName = localStorage.getItem("schemaName");
        schemaName = schemaName.split(" ").join("");
        schema(token?.access_token,schemaName).then((res) => {
            setResponse(res?.data?.schema?.properties)
            setLoader(false)
        });
    }
    useEffect(() => {
        if (response) {
            const tempData = Object.keys(response).map((key) => {
                const field = response[key];
                return {
                    ...field,
                    fieldName: field.fielddisplayname,
                    isident: field.isident === true ?"True" : "False",
                    IsMobileField: field.IsMobileField === true ?"True" : "False",
                    isSignUp: field.isSignUp === true ?"True" : "False",
                    isrequired: field.isrequired === true ?"True" : "False",
                    // isSignUp: field.isSignUp === true ?"True" : "False",
                    // isSignUp: field.isSignUp === true ?"True" : "False",
                    
                  
                };
            });
    
            setFormData(tempData);
        }
    }, [response]);
    
 
 
    useEffect(() => {
        updateData()
    }, [])


    return (
        <>
            <Header name={"Transaction Schema"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="dashboard__heading">
                                <div className="dashboard__headingLeft">
                                    <div className="dashboard__headingContent">
                                        <Link to="/create-Transaction">
                                            <Button id="voucher" className="btn btn-primary btn-lg">
                                                Create Transaction Schema
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dashboard__customersCard">
                        <div className="dashboard__header">
                                <div className="dashboard__left">
                                    <h4 id="voucher_details">Transaction Schema Details</h4>
                                </div>
                            </div>
                            <div className="dashboard__body"> <Paginated data={formData ? formData : []} columns={COLUMNS} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default TransactionSchema