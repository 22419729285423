import React, { useState, useEffect, useMemo, memo } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import { connect } from "react-redux";
import { GlobalFilter } from "./GlobalFilter";
import { getCustomer, clearCustomer, searchCustomer } from "../../../actions/customer";
import InfiniteScroll from "react-infinite-scroll-component";
import debounce from "lodash.debounce";
const Paginated = memo(({ columns, data, Customer, getCustomer, clearCustomer, searchCustomer,loader,setLoader }) => {
    const [selectPage, setSelectPage] = useState();
    const [selectedPageIndex, setSelectedPageIndex] = useState(1);
    const [pageArray, setPageArray] = useState();
    const [customerCount, setCustomerCount] = useState();

    const { getTableProps, getTableBodyProps, rows, headerGroups, page, nextPage, state, setPageSize, prepareRow, setGlobalFilter } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 50 },
        },
        useGlobalFilter,
        usePagination
    );
    const { globalFilter, pageSize } = state;
    const [inputField, setInputField] = useState(globalFilter); //eslint-disable-line
    const [visibleRowCount, setVisibleRowCount] = useState(pageSize);
    const [tempValue, setTempValue] = useState("");

    useEffect(() => {
        if (!rows.length && tempValue) {
            setLoader(true)
            const token = JSON.parse(localStorage.getItem("token_gen"));
            let id = localStorage.getItem("Id");
            getCustomer(token?.access_token,id,1,10,tempValue);
            setLoader(false)
        }
    }, [tempValue]); //eslint-disable-line

    useEffect(() => {
        setCustomerCount(Customer?.data?.data?.totalCount);
    }, [Customer]);

    useEffect(() => {
        setGlobalFilter(inputField);
    }, [inputField]); //eslint-disable-line

    useEffect(() => {
        if (customerCount) {
            let tempCustomerCount = customerCount / pageSize;
            let temp = Math.ceil(tempCustomerCount);
            setSelectPage(temp);
        } else {
            setSelectPage(0);
        }
    }, [customerCount]);

    useEffect(() => {
        let tempPageIndex = JSON.parse(localStorage.getItem("CusPageIndex"));
        if (tempPageIndex !== 1) {
            fetch();
        }
    }, []); //eslint-disable-line

    useEffect(() => {
        const temp = Array.from({ length: selectPage }, (_, index) => index + 1);
        setPageArray(temp);
    }, [selectPage]);

    useEffect(() => {
        let tempPageIndex = JSON.parse(localStorage.getItem("CusPageIndex"));
        tempPageIndex ? setSelectedPageIndex(tempPageIndex) : setSelectedPageIndex(1);
    }, []);

    const fetch = async () => {
        setLoader(true);
        await clearCustomer();
        let tempPageIndex = JSON.parse(localStorage.getItem("CusPageIndex"));
        const token = JSON.parse(localStorage.getItem("token_gen"));
        let id = localStorage.getItem("Id");
        await getCustomer(token?.access_token, id, tempPageIndex, 50," ");
        setLoader(false);
    };

    const handlePageIndex = async (event) => {
        if (event.target.value !== "") {
            const number = Number(event.target.value);
            setSelectedPageIndex(number);
            localStorage.setItem("CusPageIndex", JSON.stringify(number));
            fetch();
            // gotoPage(number);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setTempValue(value);
        setGlobalFilter(value);
        if (value === "") {
            clearCustomer();
            const token = JSON.parse(localStorage.getItem("token_gen"));
            let tempPageIndex = JSON.parse(localStorage.getItem("CusPageIndex"));
            let id = localStorage.getItem("Id");
            getCustomer(token?.access_token, id, tempPageIndex, pageSize," ");
        }
    };
    const debouncedResults = useMemo((e) => {
        return debounce(handleInputChange, 500);
    }, []); //eslint-disable-line

    const handleNext = () => {
        if (selectedPageIndex < pageArray?.length) {
            setSelectedPageIndex(selectedPageIndex + 1);
            localStorage.setItem("CusPageIndex", JSON.stringify(selectedPageIndex + 1));
            fetch();
            nextPage();
        }
    };
    const handlePrevious = () => {
        if (selectedPageIndex > 1) {
            setSelectedPageIndex(selectedPageIndex - 1);
            localStorage.setItem("CusPageIndex", JSON.stringify(selectedPageIndex - 1));
            fetch();
            // previousPage();
        }
    };

    const memoGlobleFilter = useMemo(() => {
        return <GlobalFilter filter={inputField} setFilter={setInputField} page={page} searchCustomer={searchCustomer} getCustomer={getCustomer} debouncedResults={debouncedResults} />;
    }, [globalFilter, page, searchCustomer]);

    return (
        <>
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="customTable">
                <div className="customTable__header">
                    <div className="customTable__left">
                        <div className="customTable__showingRecords">
                        </div>
                    </div>
                    <div className="customTable__right">{memoGlobleFilter}</div>
                </div>
                <div className="customTable__body">
                    <div className="table-responsive">
                        {data?.length ? (
                            <InfiniteScroll
                                dataLength={visibleRowCount}
                                next={() => {
                                    setVisibleRowCount((prevRowCount) => prevRowCount + pageSize);
                                }}
                                hasMore={visibleRowCount < rows.length}
                                // loader={<h4>Loading...</h4>}
                                // endMessage={<p>No more records to load.</p>}
                            >
                                <table className="table table-striped table-bordered" {...getTableProps()}>
                                    <thead>
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map((column) => (
                                                    <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>

                                    <tbody {...getTableBodyProps()}>
                                        {rows.slice(0, visibleRowCount).map((row, rowIndex) => {
                                            prepareRow(row);
                                            return (
                                                <tr {...row.getRowProps()} key={rowIndex}>
                                                    {row.cells.map((cell) => {
                                                        return <td {...cell.getCellProps()}>{cell.render("Cell") ? cell.render("Cell") : "Null"}</td>;
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </InfiniteScroll>
                        ) : (
                            <table className="table table-striped table-bordered" {...getTableProps()}>
                                <thead>
                                    {headerGroups.map((headerGroup) => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map((column) => (
                                                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={9}>
                                            <p style={{ textAlign: "center", margin: 0 }}>No data found</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
                <div className="customTable__footer">
                    <div className="customTable__left">
                        <div className="customTable__totalTransactions">
                            <p>
                                Total Employee: <span>{rows.length}</span>
                            </p>
                        </div>
                    </div>
                    {rows.length === 1 ?
                        null : 
                    <div className="customTable__right">
                        <div className="customTable__top">
                        <div className="customTable__totalPages">
                            {pageArray && (
                                <p>
                                    Page
                                    <select className="form-control selectArrow" value={selectedPageIndex} onChange={handlePageIndex}>
                                        {pageArray?.length ? (
                                            pageArray.map((value) => (
                                                <option key={value} value={value}>
                                                    {value}
                                                </option>
                                            ))
                                        ) : (
                                            <option key={0} value={0}>
                                                {0}
                                            </option>
                                        )}
                                    </select>
                                    of <span>{pageArray?.length}</span>
                                </p>
                            )}
                        </div>
                    </div>
                       
                        <div className="customTable__bottom customTable__bottom__2">
                            <div className="customTable__buttons">
                                {/* <span className="btn btn-outline-primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
								{"First"}
							</span> */}
                                <span className="btn btn-outline-primary" onClick={() => handlePrevious()} disabled={selectedPageIndex > 1 ? false : true}>
                                    {"Previous"}
                                </span>
                                <span className="btn btn-outline-primary" onClick={handleNext} disabled={pageArray?.length > 0 ? (selectedPageIndex === pageArray?.length ? true : false) : true}>
                                    {"Next"}
                                </span>
                                {/* <span className="btn btn-outline-primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
								{"Last"}
							</span> */}
                            </div>
                        </div>
                    </div>
}
                </div>
            </div>
        </>
    );
});
const mapStateToProps = (state) => ({
    Customer: state.Customer,
});
const mapDispatchToProps = {
    getCustomer,
    clearCustomer,
    searchCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(Paginated);
