import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { COLUMNS } from "./column";
import Header from "../../layout/Header";
import Paginated from "./Paginated";
import { getSchemaList } from "../../../actions/auth";


function SchemaSetting() {
    const [formData, setFormData] = useState([]);
    const [response,setResponse] = useState([])

    const [loader, setLoader] = useState(false);
    const updateData = () => {
        setLoader(true)
        const token = JSON.parse(localStorage.getItem("token_gen"));
        getSchemaList(token?.access_token).then((res) => {
            setResponse(res?.schema?.properties)
            setLoader(false)
        });
    }
    useEffect(() => {
        if (response) {
            const tempData = Object.keys(response).map((key) => {
                const field = response[key];
                const isCustomerCode = field.fielddisplayname === "Customer Code";
                return {
                    ...field,
                    fieldName: field.fielddisplayname,
                    isident: field.isIdent === true ?"True" : "False",
                    IsMobileField: field.IsMobileField === true ?"True" : "False",
                    isSignUp: field.isSignUp === true ?"True" : "False",
                    isrequired: field.isrequired === true ?"True" : "False",
                    action : isCustomerCode ? null :  (
                                       <Link
                                           className="edit_btn"
                                           state={{ key, field }} 
                                      to="/create-Schema"
                                           style={{ display: "flex", justifyContent: "center" }}
                                           csvalue="Edit"
                                       >
                                           <i className="fa-solid fa-pen"></i>
                                           <div className="show_div">Edit</div>
                                       </Link>
                                   )
                    // isSignUp: field.isSignUp === true ?"True" : "False",
                    // isSignUp: field.isSignUp === true ?"True" : "False",
                    
                  
                };
            });
    
            setFormData(tempData);
        }
    }, [response]);
    
 
 
    useEffect(() => {
        updateData()
    }, [])


    return (
        <>
            <Header name={"Customer Schema"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="row">
                        {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="dashboard__heading">
                                <div className="dashboard__headingLeft">
                                    <div className="dashboard__headingContent">
                                        <Link to="/create-Schema">
                                            <Button id="voucher" className="btn btn-primary btn-lg">
                                                Create Customer Schema
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="dashboard__customersCard">
                        <div className="dashboard__header">
                                <div className="dashboard__left">
                                    <h4 id="voucher_details">Customer Schema Details</h4>
                                </div>
                            </div>
                            <div className="dashboard__body"> <Paginated data={formData ? formData : []} columns={COLUMNS} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default SchemaSetting