import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import { useNavigate } from "react-router-dom";
import { getDate, selectValueGetter } from "../../utils/helper";
import ProgramsCard from "../partial/ProgramsCard";
import { getCampaigns, clearCampaigns } from "../../actions/campaigns";
import Page from "./Pagination";
import SelectValidator from "../partial/SelectValidator";
import { ValidatorForm } from "react-form-validator-core";
import { Modal, Button, CloseButton } from "react-bootstrap";
import { connect } from "react-redux";
// import { getmerchantschema } from "./campaigns/services";

const LProgram = ({ getCampaigns, clearCampaigns, Campaigns, modalView }) => {
    const [data, setData] = useState([]);
    // eslint-disable-next-line
    const [selectedCampaign, setSelectedCampaign] = useState();

    const [expired, setExpired] = useState([]);
    const [active, setActive] = useState([]);
    const [activeTrue, setActiveTrue] = useState(false);
    const [inActiveTrue, setInActiveTrue] = useState(false);
    const [inActive, setInActive] = useState(false);
    const [expiredTrue, setExpiredTrue] = useState(false);
    const [normalView, setNormalView] = useState(true);
    const [show, setShow] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);
    const [total, setTotal] = useState(0);
    const [loader, setLoader] = useState(false);
    const [valueselect, setValueSelect] = useState("all");
    const [showButton, setShowButton] = useState(false);
    const [option] = useState([
        { label: "All Campaigns", value: "all" },
        { label: "Active campaigns", value: "active" },
        { label: "Inactive campaigns", value: "inActive" },
        { label: "Expired campaigns", value: "expired" },
    ]);
    const [options] = useState([
        { label: "Points", value: "points" },
        { label: "Referral", value: "referral" },
        { label: "Voucher", value: "voucher" },
    ]);

    const pageSize = 6;
    const navigate = useNavigate();
    useEffect(() => {
        fetch();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        activeExpired();
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        campaign();
        // eslint-disable-next-line
    }, [Campaigns]);

    const fetch = async () => {
        setLoader(true);
        await clearCampaigns();
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = localStorage.getItem("Id");
        // const merchantCode = localStorage.getItem("merchantcode");
        await getCampaigns(token?.access_token, id);
        // let response = await getmerchantschema(token?.access_token, merchantCode);
        // let temp = [];
        // if (response.length > 0) {
        //     response.map((item) => temp.push({ label: item.schemaName, value: item.schemaId }));
        //     setOptions(temp);
        // } else {
        //     temp.push({ label: "No options" });
        //     setOptions(temp);
        // }

        setLoader(false);
    };

    const campaign = async () => {
        setHasNextPage(Campaigns?.data.length >= pageSize);
        setHasPreviousPage(currentPage > 0);
        setTotal(Campaigns?.data.length);
        if (Campaigns?.data.length > 0) {
            setData(Campaigns?.data.reverse());
            setShowButton(true);
        } else {
            setShowButton(false);
            setData([]);
        }
    };
    const handleClose = () => setShow(false);

    const activeExpired = () => {
        let active = [];
        let inActive = [];
        let expired = [];
        let newDate = new Date();
        for (let i = 0; i < data.length; i++) {
            let date = new Date(data[i].endDateTime);
            let statusTime = data[i].status
            if (newDate - date <= 0) {
              if(statusTime === true) {
                active.push(data[i]);
              }
              else {
                inActive.push(data[i]);
              }
               
            } else {
                expired.push(data[i]);
            }
            setActive(active);
            setInActive(inActive);
            setExpired(expired);
        }
    };

    const unPeople = (e) => {
        if (e.value === "all") {
            if (active.length > 0) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
            setValueSelect("all");
            setNormalView(true);
            setActiveTrue(false);
            setInActiveTrue(false)
            setExpiredTrue(false);
            setCurrentPage(0);
            setTotal(data.length);
        } else if (e.value === "active") {
            if (active.length > 0) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
            setValueSelect("active");
            setNormalView(false);
            setActiveTrue(true);
            setInActiveTrue(false)
            setExpiredTrue(false);
            setHasNextPage(active.length >= pageSize);
            setCurrentPage(0);
            setHasPreviousPage(currentPage > 0);
            setTotal(active.length);
        }
        else if (e.value === "inActive") {
            if (inActive.length > 0) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
            setValueSelect("inActive");
            setNormalView(false);
            setActiveTrue(false);
            setInActiveTrue(true)
            setExpiredTrue(false);
            setHasNextPage(inActive.length >= pageSize);
            setCurrentPage(0);
            setHasPreviousPage(currentPage > 0);
            setTotal(inActive.length);
        }  else if (e.value === "expired") {
            if (expired.length > 0) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
            setValueSelect("expired");
            setNormalView(false);
            setActiveTrue(false);
            setInActiveTrue(false)
            setExpiredTrue(true);
            setHasNextPage(expired.length >= pageSize);
            setHasPreviousPage(currentPage > 0);
            setTotal(expired.length);
            setCurrentPage(0);
        }
    };
    const pageCount = Math.ceil(total / pageSize);

    // const handleSelect = (e) => {
    //     // if (e.target.value) {
    //     //     setSelectedCampaign(e.target.value);
    //     //     setShow(false);
    //     console.log(e);
    //     if (e.value === "points") {
    //         navigate("/my-campaigns/create-campaign");
    //         setValueSelected("points");
    //     } else if (e.value === "referral") {
    //         navigate("/referral-program/create-campaign");
    //         setValueSelected("referral");
    //     } else if (e.target.value === "signup") {
    //         navigate("/signup-program/create-campaign");
    //     }
    // };

    const onHandleSelect = (e) => {
        if (e.value) {
            navigate("/my-campaigns/create-campaign", {
                state: e.value,
            });
        }
    };

    const handleCampaigns = () => {
        setShow(true);
    };
    const onSubmit = () => {};

    return (
        <>
            <Header name={"My Campaigns"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="dashboard__heading align-items-end">
                                <div className="dashboard__headingLeft">
                                    <div className="dashboard__headingContent">
                                        <h3>My Campaigns</h3>
                                        <p>Set how your customers will earn points.</p>
                                        <Button id="crate_new_cmp" disabled={localStorage.getItem("firstLogin")} onClick={handleCampaigns} className="btn btn-primary btn-lg">
                                            Create New Campaign
                                        </Button>
                                    </div>
                                </div>
                                <div className="dashboard__headingRight">
                                    <div className="form-group mb-0">
                                        <ValidatorForm onSubmit={onSubmit}>
                                            <SelectValidator placeholder="Select" className="" name="choice" value={selectValueGetter(option, valueselect)} options={option} onChange={unPeople} />
                                        </ValidatorForm>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
    {normalView && Array.isArray(data) &&
        data.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((item, index) => (
            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4" key={index}>
                <ProgramsCard 
                    path="/my-campaigns/edit-campaign" 
                    id={item?.code} 
                    data={item}  
                    value={item?.earningRule?.rules[0]?.data[0]?.Value} 
                    earningvalue={item?.earningRule?.rules[0]?.data[0]?.RedeemType} 
                    index={currentPage * 6 + index} 
                    heading={item?.name} 
                    startDate={getDate(item?.startDateTime, "DD-MM-YYYY")} 
                    endDate={getDate(item?.endDateTime, "MM-DD-YYYY")} 
                    text={item?.description} 
                    campaignCode={item.code} 
                    sample={false} 
                    firstData={index === 0} 
                    status={item.status} 
                />
            </div>
        ))
    }
    {activeTrue && Array.isArray(active) &&
        active.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((item, index) => (
            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4" key={index}>
                <ProgramsCard 
                    path="/my-campaigns/edit-campaign" 
                    id={item?.code} 
                    data={item} 
                    value={item?.earningRule?.rules[0]?.data[0]?.Value} 
                    earningvalue={item?.earningRule?.rules[0]?.data[0]?.RedeemType} 
                    index={currentPage * 6 + index} 
                    heading={item?.name} 
                    startDate={getDate(item?.startDateTime, "DD-MM-YYYY")} 
                    endDate={getDate(item?.endDateTime, "MM-DD-YYYY")} 
                    text={item?.description} 
                    campaignCode={item.code} 
                    sample={false} 
                    status={item.status} 
                />
            </div>
        ))
    }
    {inActiveTrue && Array.isArray(inActive) &&
        inActive.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((item, index) => (
            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4" key={index}>
                <ProgramsCard 
                    path="/my-campaigns/edit-campaign" 
                    id={item?.code} 
                    data={item} 
                    value={item?.earningRule?.rules[0]?.data[0]?.Value} 
                    earningvalue={item?.earningRule?.rules[0]?.data[0]?.RedeemType} 
                    index={currentPage * 6 + index} 
                    heading={item?.name} 
                    startDate={getDate(item?.startDateTime, "DD-MM-YYYY")} 
                    endDate={getDate(item?.endDateTime, "MM-DD-YYYY")} 
                    text={item?.description} 
                    campaignCode={item.code} 
                    sample={false} 
                    status={item.status} 
                />
            </div>
        ))
    }
    {expiredTrue && Array.isArray(expired) &&
        expired.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((item, index) => (
            <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4" key={index}>
                <ProgramsCard 
                    path="/my-campaigns/edit-campaign" 
                    id={item.code} 
                    data={item} 
                    value={item?.earningRule?.rules[0]?.data[0]?.Value} 
                    earningvalue={item?.earningRule?.rules[0]?.data[0]?.RedeemType} 
                    index={currentPage * 6 + index} 
                    heading={item.name} 
                    startDate={getDate(item.startDateTime, "DD-MM-YYYY")} 
                    endDate={getDate(item.endDateTime, "MM-DD-YYYY")} 
                    text={item?.description} 
                    campaignCode={item.code} 
                    sample={false} 
                    status={item.status} 
                />
            </div>
        ))
    }
</div>

                        {showButton && (
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="dashboard__paginationBox">
                                    <Page currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} value={5} />
                                </div>
                            </div>
                        )  }
                          {Campaigns.no_cmp &&  <h4>There is no campaign to show</h4>
                        }
                    </div>
                </div>
            </div>
            <Modal show={show} id="showm" onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "20px" }}>Create New Campaign</Modal.Title>
                    <CloseButton onClick={handleClose} variant="white" />
                </Modal.Header>
                <Modal.Body>
                    <p>Please select which type of Campaign you want to create</p>
                    <div className="form-group">
                        <ValidatorForm onSubmit={onSubmit}>
                            <SelectValidator placeholder="Select" className="" name="choice" options={options} onChange={onHandleSelect} />
                        </ValidatorForm>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
const mapStateToProps = (state) => ({
    Campaigns: state.Campaigns,
    modalView: state.intro.modalOpen,
});
const mapDispatchToProps = {
    getCampaigns,
    clearCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(LProgram);
