import React, { useEffect, useState } from 'react'
import { ValidatorForm } from 'react-form-validator-core';
import { getDate, selectValueGetter } from '../../../utils/helper';
import Header from '../../layout/Header';
import TextValidator from '../../partial/TextValidator';
import { QueryBuilder, formatQuery } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import { getCustomer } from '../../../actions/schema';
import { createCustomer, getCustomerGroupDataBySelectValue, updateCustomer } from '../campaigns/services';
import { decrypt } from '../../../utils/encodingdecoding';
import { getCustomerGroupById, getCustomerGroupList } from '../../../actions/campaigns';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Paginated from './Paginated';
import { COLUMNS } from './addColumn';
import { getMembership } from '../../../actions/auth';
import SelectValidator from '../../partial/SelectValidator';

function AddMembership() {
    const currencyType = [
        { label: 'INR', value: 'INR' },
        { label: 'USD', value: 'USD' },
        { label: 'AED', value: 'AED' },
    ];
    const [currencyValue, setCurrencyValue] = useState("");
    const [getId, setGetId] = useState()
    const [groupId, setGroupId] = useState()
    const [loader, setLoader] = useState(false);
    const [response, setResponse] = useState()
    const [setList, setFormList] = useState([])
    const [formData, setFormData] = useState({
        membershipName: "",
        fee: "",
        currency: []
    });
    const { membershipName, fee, currency } = formData;
    const handleCurrency = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setCurrencyValue(selectedValues);
        setFormData({ ...formData, currency: selectedValues });
    };
    const onChange = (e) => {
        setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
    };
    console.log(formData)

    const updateData = () => {
        setLoader(true)
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = localStorage.getItem("Id");
        getMembership(token?.access_token, id).then((res) => {
            setResponse(res?.data)
            setLoader(false)
        });
    }

    useEffect(() => {
        updateData()
    }, [])

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        if (getId) {
            const response = await updateCustomer(token?.access_token, formData);
            updateData();
            if (response?.message === "Request processed successfully.") {
                swal({
                    position: "center",
                    icon: "success",
                    title: "Membership Updated",
                    showConfirmButton: false,
                    timer: 2000,
                });
                setGroupId()
                setFormData({
                    membershipName: "",
                    fee: "",
                    currency: []
                });
            } else {
                swal({
                    position: "center",
                    icon: "error",
                    title: response?.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        } else {
            const response = await createCustomer(token?.access_token, formData);
            if (response?.message === "Request processed successfully.") {
                swal({
                    position: "center",
                    icon: "success",
                    title: "Membership Created",
                    showConfirmButton: false,
                    timer: 2000,
                });
                updateData();
                setFormData({
                    membershipName: "",
                    fee: "",
                    currency: []
                });

            } else {
                swal({
                    position: "center",
                    icon: "error",
                    title: response?.message,
                    showConfirmButton: false,
                    timer: 2000,
                });
            }
        }
        setLoader(false);
    };

    const handleLinkClick = (id, customerGroupId) => {
        setGetId(id)
        setGroupId(customerGroupId)
        setLoader(true)
        const token = JSON.parse(localStorage.getItem("token_gen"));
        getCustomerGroupById(token?.access_token, id).then((res) => {
            window.scrollTo(0, 0);
            const { customerGroupName, advanceSqlQuery } = res.data;
            setFormData((prevState) => ({
                ...prevState,
                name: customerGroupName,
                sql: advanceSqlQuery,
            }));
        });
        setLoader(false)
    };

    useEffect(() => {
        const tempData = response;
        if (tempData) {
            let serialNumber = 1;
            tempData?.map((val, ind) => {
                val.sNo = serialNumber++;
                val.membershipName = val.membershipName;
                val.createDate = getDate(val.createdDate, "DD-MMM-YYYY");
                val.action = (
                    <>
                        <Link
                            className="edit_btn"
                            state={val.id}
                            onClick={() => handleLinkClick(val.id, val.customerGroupId)}
                            style={{ display: "flex", justifyContent: "center" }}
                            csvalue="Edit"
                        >
                            <i className="fa-solid fa-pen"></i>
                            <div className="show_div">Edit</div>
                        </Link>
                        <Link
                            className="edit_btn"
                            state={val.id}
                            onClick={() => handleLinkClick(val.id, val.customerGroupId)}
                            style={{ display: "flex", justifyContent: "center" }}
                            csvalue="Edit"
                        >
                            <i className="fa-solid fa-pen"></i>
                            <div className="show_div">Edit</div>
                        </Link>
                    </>
                );
                return val;
            });
        }
        setFormList(tempData);
    }, [response]);

    return (
        <div>
            <Header name={"Membership"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className='dashboard__content'>
                <ValidatorForm onSubmit={onSubmit} className="dashboard__customerViewMain">
                    <div className="dashboard__transactionsCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                {groupId ? (
                                    <h4 id='transction_list'>Update Membership</h4>
                                ) : (
                                    <h4 id='transction_list'>Create Membership</h4>
                                )}

                            </div>
                        </div>
                        <div className='dashboard__customerViewCard'>
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <div className="row">
                                        <label className="col-md-4 col-form-label">Membership Name</label>
                                        <div className="col-md-8">
                                            <TextValidator className="form-control" validators={["required"]} value={membershipName} placeholder="Membership Name" name="membershipName" maxLength="50" errorMessages={"membership name is required"} onChange={onChange} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="row">
                                        <label className="col-md-4 col-form-label">Fee</label>
                                        <div className="col-md-8">
                                            <TextValidator className="form-control" validators={["required"]} value={fee} placeholder="Enter Fee" name="fee" maxLength="50" errorMessages={"fee is required"} onChange={onChange} />
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="form-group withIcon">
                                        <label>Membership Name</label>
                                        <TextValidator className="form-control" validators={["required"]} value={membershipName} placeholder="Membership Name" name="membershipName" maxLength="50" errorMessages={"membership name is required"} onChange={onChange} />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="form-group withIcon">
                                        <label>Fee</label>
                                        <TextValidator className="form-control" validators={["required"]} value={fee} placeholder="Enter Fee" name="fee" maxLength="50" errorMessages={"fee is required"} onChange={onChange} />
                                    </div>
                                </div> */}
                            </div>



                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <label className="col-md-4 col-form-label">Currency</label>
                                        <div className="col-md-8">
                                        <SelectValidator
                                            isMulti
                                            validators={["required"]}
                                            options={currencyType}
                                            value={currencyType.filter(option => currencyValue?.includes(option.value))}
                                            // value={currencyValue ? selectValueGetter(currencyType, currencyValue) : []}
                                            onChange={handleCurrency}
                                            errorMessages={"currency is required"}
                                        />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 d-flex justify-content-end align-items-center">
                                {groupId ? (
                                    <button type='submit' className="btn btn-primary">
                                        Update
                                    </button>
                                ) : (
                                    <button type='submit' className="btn btn-primary">
                                        Create
                                    </button>
                                )}
                                </div>




                                {/* <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="form-group withIcon">
                                        <label>Currency</label>
                                        <SelectValidator
                                            isMulti
                                            options={currencyType}
                                            value={currencyValue ? selectValueGetter(currencyType, currencyValue) : []}
                                            onChange={handleCurrency}
                                        />
                                    </div>
                                </div> */}

                            </div>


                            {/* <div className='customer_group_form'>
                                {groupId ? (
                                    <button type='submit' className="btn btn-primary btn-lg">
                                        Update
                                    </button>
                                ) : (
                                    <button type='submit' className="btn btn-primary btn-lg">
                                        Create
                                    </button>
                                )}

                            </div> */}
                        </div>
                    </div>
                </ValidatorForm>
                <div className="dashboard__customersCard">
                    <div className="dashboard__header">
                        <div className="dashboard__left">
                            <h4 id='transction_list'>Membership List</h4>
                        </div>
                    </div>
                    <div className="dashboard__body">
                          <table className="table table-striped table-bordered">
                                                  <thead>
                                                    <tr>
                                                      <th>S.No.</th>
                                                      <th>Membership Name</th>
                                                      <th>Fee</th>
                                                      <th>Currency</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>

                                                       <tr>
                                                        <td>1</td>
                                                        <td>Gym Membership</td>
                                                        <td>500</td>
                                                        <td>USD</td>
                                                       </tr>
                                                       <tr>
                                                        <td>2</td>
                                                        <td>Canteen Membership</td>
                                                        <td>1000</td>
                                                        <td>USD</td>
                                                       </tr>
                                                  </tbody>
                                                </table>
                        {/* <Paginated data={setList ? setList : []} columns={COLUMNS} /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddMembership

