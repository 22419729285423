import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../../partial/TextValidator";
import CKEditor from "ckeditor4-react";
import SmsSetting from "./SmsSetting";
import { EMAIL_TEMPLATE_COLUMN } from "./emailTemplateCol";
import SelectValidator from "../../partial/SelectValidator";
import {
  customerPortalSetting,
  deleteCustomerPortalSetting,
  getEmailTemplateSetting,
  schema,
} from "../campaigns/services";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { getDropdownObject } from "../../../utils/helper";
import EmailTemplateModal from "./EmailTemplateModal";
import { decrypt } from "../../../utils/encodingdecoding";
import { getCustomer } from "../../../actions/schema";

function EmailTemplate() {
  const [response, setResponse] = useState();
  const [templateModal, showTemplateModal] = useState({
    isOpen: false,
    body: "",
  });
  const [data, setData] = useState();
  const [schemaData, setSchemaData] = useState();
  const [error, setError] = useState(null);
  const [typeDisplay] = useState([
    { value: "", label: "Select" },
    { value: "Transaction", label: "Transaction" },
    { value: "Redemption", label: "Redemption" },
    { value: "Voucher Purchase", label: "Voucher Purchase" },
    { value: "Giftcard Purchase", label: "GiftCard Purchase" },
  ]);
  console.log(schemaData)
  const [loader, setLoader] = useState(false);
  const [emailSetting, setEmailSetting] = useState({
    sendgridApiKey: "",
    fromEmail: "",
    fromName: "",
    EmailType: "",
    Subject: "",
    Body: "",
    status: false,
    emailPlaceholder: [],
    templateName: "",
    selectedType: "",
  });
  const {
    sendgridApiKey,
    fromEmail,
    fromName,
    EmailType,
    selectedType,
    Subject,
    Body,
    status,
    templateName,
  } = emailSetting;

  const handleViewModal = (body) => {
    showTemplateModal((prev) => ({
      ...prev,
      isOpen: true,
      body: body,
    }));
  };

  const handleDeleteSMS = (id, type) => {
    setLoader(true);
    const tempToken = JSON.parse(
      localStorage.getItem("token_gen")
    )?.access_token;

    deleteCustomerPortalSetting(tempToken, id).then((res) => {
      setLoader(false);
      if (res.message === "Settings updated successfully") {
        swal({
          position: "center",
          icon: "success",
          title: `${type} delete Successfully`,
          showConfirmButton: false,
          timer: 2000,
        });
        fetchDataBanner();
      } else {
        swal({
          position: "center",
          icon: "error",
          title: res.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    });
  };
  console.log(response);
  useEffect(() => {
    const temp = response;

    if (temp) {
      temp?.map((val) => {
        console.log(val);
        return (
          (val.Subject = val.Subject),
          (val.fromName = val.fromName),
          (val.fromEmail = val.fromEmail),
          (val.sendgridApiKey = val.sendgridApiKey),
          (val.EmailType = val.EmailType),
          (val.action = (
            <Link
              className="edit_btn"
              onClick={() => handleViewModal(val.Body)}
            >
              <div className="show_div">View</div>
            </Link>
          )),
          (val.status = val.status?.toString()),
          (val.delete = (
            <Link onClick={() => handleDeleteSMS(val.id, "Email Template")}>
              <i
                className="fa fa-times-circle"
                style={{ fontSize: "18px" }}
              ></i>
            </Link>
          ))
        );
      });

      setData(temp);
    }
  }, [response]);
  const fetchDataBanner = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("token_gen"));
    const id = localStorage.getItem("Id");
    const res = await getEmailTemplateSetting(token?.access_token, id);
    setResponse(res.data?.EmailTemplateSetting);

    if (res.message === "Email template settings retrieved successfully") {
      const { EmailTemplateSetting } = res.data;
      if (EmailTemplateSetting?.length > 0) {
        EmailTemplateSetting.map((val) => {});
      }
      // setEmailSetting((prev) => ({
      //     ...prev,
      //     EmailTemplateSetting: EmailTemplateSetting,
      // }));
    }
    setLoader(false);
  };

  useEffect(() => {
    fetchDataBanner();
  }, []);
  useEffect(() => {
    if (selectedType || templateName) {
      setError("");
      return;
    }
  }, [selectedType, templateName]);

  const handleSubmitEmail = async () => {
    const tempToken = JSON.parse(
      localStorage.getItem("token_gen")
    )?.access_token;
    if (!selectedType && !templateName) {
      setError("Either Email Template or Template Name must have a value.");
      return;
    } else {
      const updatedEmailSetting = {
        ...emailSetting,
        EmailType: templateName || selectedType,
      };
      delete updatedEmailSetting.selectedType;
      delete updatedEmailSetting.templateName;
      setLoader(true);
      customerPortalSetting(tempToken, updatedEmailSetting).then((res) => {
        setLoader(false);
        if (res.message === "Email Template Updated Successfully") {
          setEmailSetting((prev) => ({
            ...prev,
            sendgridApiKey: "",
            fromEmail: "",
            fromName: "",
            EmailType: "",
            Subject: "",
            Body: "",
            status: false,
            templateName: "",
            selectedType: "",
          }));
          swal({
            position: "center",
            icon: "success",
            title: "Email Template Setting Set Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
          fetchDataBanner();
        } else {
          swal({
            position: "center",
            icon: "error",
            title: res.message,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      });
    }
  };

  const handleToggle = (e) => {
    setEmailSetting({ ...emailSetting, [e.target.name]: e.target.checked });
  };

  const onChangeEmail = (e) => {
    setEmailSetting({ ...emailSetting, [e.target.name]: e.target.value });
  };
  const handleTypeChange = (e) => {
    setEmailSetting((prev) => ({
      ...prev,
      EmailType: e.value,
      templateName: "",
      selectedType: e.value,
    }));
  };
  const onChangeTemplateName = (e) => {
    const { value } = e.target;
    setEmailSetting((prev) => ({
      ...prev,
      EmailType: value,
      templateName: value,
      selectedType: "",
    }));
  };

  const handleEditorChange = (e) => {
    const data = e.editor.getData();
    setEmailSetting((prev) => ({
      ...prev,
      Body: data,
    }));
  };
  const fetchSchema = async () => {
    setLoader(true);
    const token = JSON.parse(localStorage.getItem("token_gen"));
    let schemaName = localStorage.getItem("schemaName");
    schemaName = schemaName.split(" ").join("");
    const response = await getCustomer(token?.access_token, schemaName).then((res)=>{
      let newdata = res?.schema?.properties;
      let temp = [];

      for (const property in newdata) {
          temp.push({
              label: property,
              value: property,
              type: newdata[property].type,
              fielddatatype: newdata[property].fielddatatype,
          });
      }
      temp.push({
          label: "valueOfPoints",
          value: "valueOfPoints",
          type: "number",
          fielddatatype: "integer", 
      });
      temp.push({
          label: "voucherCode",
          value: "voucherCode",
          type: "string",
          fielddatatype: "text",
      });

      setSchemaData(temp);
      setLoader(false);
    })
    
   
};


      useEffect(() => {
          fetchSchema();
      }, []);
      const handleSchemaChange = (selectedOptions) => {
        const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setEmailSetting(prevState => ({
          ...prevState,
          emailPlaceholder: selectedValues
        }));
      };
      
      

  console.log(emailSetting);
  return (
    <div>
      <Header name={"Email Template"} />
      {loader && (
        <div className="mainPreloaderMain">
          <div className="mainPreloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div className="dashboard__content">
        <div className="dashboard__transactionsCard">
          <div className="dashboard__header">
            <div className="dashboard__left">
              <h4 id="transction_list">Email Template</h4>
            </div>
          </div>
          <ValidatorForm
            className="dashboard__customerViewMain"
            onSubmit={handleSubmitEmail}
          >
            <div className="dashboard__customerViewCard">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                  <div className="form-group withIcon">
                    <label>Subject</label>
                    <TextValidator
                      placeholder="Subject"
                      className="form-control"
                      name="Subject"
                      onChange={onChangeEmail}
                      value={Subject}
                      validators={["required"]}
                      errorMessages={["Subject is required"]}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                  <div className="form-group withIcon">
                    <label>From Name</label>
                    <TextValidator
                      placeholder="Name"
                      type="text"
                      className="form-control"
                      name="fromName"
                      onChange={onChangeEmail}
                      value={fromName}
                      validators={["required"]}
                      errorMessages={["Name is required"]}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                  <div className="form-group withIcon">
                    <label>Form Email</label>
                    <TextValidator
                      placeholder="novus@loyalty.com"
                      className="form-control"
                      name="fromEmail"
                      onChange={onChangeEmail}
                      value={fromEmail}
                      validators={["required", "isEmail"]}
                      errorMessages={[
                        "From email is required",
                        "Email is not valid",
                      ]}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                  <div className="form-group withIcon">
                    <label>Send Grid APIkey</label>
                    <TextValidator
                      placeholder="API Key"
                      type="text"
                      className="form-control"
                      name="sendgridApiKey"
                      onChange={onChangeEmail}
                      value={sendgridApiKey}
                      validators={["required"]}
                      errorMessages={["API Key is required"]}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                  <div className="form-group withIcon">
                    <label>Email Template</label>
                    <div className="row">
                      <div className="col-11">
                        <SelectValidator
                          placeholder="Select Type"
                          className="custom-ReactSelect bordered"
                          name="selectedType"
                          value={getDropdownObject(selectedType, typeDisplay)}
                          options={typeDisplay}
                          onChange={handleTypeChange}
                        />
                        {error && <div style={{ color: "red" }}>{error}</div>}
                      </div>
                      <div className="col-1 text-center px-0 pt-2">OR</div>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                  <div className="form-group withIcon">
                    <label>Template Name</label>
                    <TextValidator
                      placeholder="Enter template name"
                      className="form-control"
                      name="templateName"
                      onChange={onChangeTemplateName}
                      value={templateName}
                    />
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                  <div
                    className="person"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <p
                      className="voucher mb-2"
                      style={{
                        display: "flex",
                        width: "25%",
                        marginBottom: "0",
                        color: "black",
                      }}
                    >
                      Status
                    </p>
                    <div className="">
                      <label className="toggle">
                        <input
                          type="checkbox"
                          name="status"
                          checked={status}
                          onChange={handleToggle}
                        />
                        <span className="slider"></span>
                        <span
                          className="labels"
                          data-on="true"
                          data-off="false"
                        ></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                            <div className="form-group">
                                                <label>Email PlaceHolder</label>
                                                <SelectValidator
                                                isMulti
                                                placeholder="Select Email PlaceHolder"
                                                    className="custom-ReactSelect bordered"
                                                    name="durationfieldName"
                                                    onChange={handleSchemaChange} // Handle selection changes
                                                    // value={selectValueGetter(durationOpt, durationfieldName)}
                                                    options={schemaData}
                                                    // onChange={(e) => onSelectChange(e, "durationfieldName")}
                                                />
                                            </div>
                                        </div>

                <div className="col-xl-12">
                  <CKEditor
                    config={{ versionCheck: false }}
                    data={Body}
                    onChange={handleEditorChange}
                    name="Body"
                  />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 py-3">
                  <div
                    className="button-section"
                    style={{ justifyContent: "flex-end", display: "flex" }}
                  >
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
                <div>
                  <SmsSetting
                    columns={EMAIL_TEMPLATE_COLUMN}
                    data={data || []}
                  />
                </div>
              </div>
            </div>
          </ValidatorForm>

          {templateModal?.isOpen && (
            <EmailTemplateModal
              show={templateModal?.isOpen}
              close={() =>
                showTemplateModal((prev) => ({ ...prev, isOpen: false }))
              }
              body={templateModal.body}
              header={"Email Template"}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default EmailTemplate;
