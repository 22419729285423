import React from "react";

export const GlobalFilter = ({ filter, setFilter }) => {
    return (
        <div className="customTable__searchFilter">
            <label>Search:</label>
            <input className="form-control" type="text" value={filter || ""} onChange={(e) => setFilter(e.target.value)} />
        </div>
    );
};
