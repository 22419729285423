import React, { useEffect, useLayoutEffect } from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import { Provider } from "react-redux";
// import { SocketProvider } from '../src/context/socket/SocketProvider';

/// For Page's Links

import SignUp from "./components/auth/SignUp";
import SignIn from "./components/auth/SignIn";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Thankyou from "./components/auth/Thankyou";
import Sidebar from "./components/layout/Sidebar";

// User Journey Start

import VerifyYourEmail from "./components/auth/VerifyYourEmail";
import UploadExistingData from "./components/pages/userJourney/UploadExistingData";
import AnalyticalReport from "./components/pages/userJourney/AnalyticalReport";
import BusinessGoalsSelection from "./components/pages/userJourney/BusinessGoalsSelection";
import Dashboard from "./components/pages/userJourney/Dashoard";
import GrowthProspect from "./components/pages/userJourney/GrowthProspect";

// User Journey End

import LProgram from "./components/pages/LProgram";
import CustomersList from "./components/pages/CustomersList";
import ImportCustomers from "./components/pages/customersList/ImportCustomers";
import TransactionsList from "./components/pages/TransactionsList";
import NewLoyaltyCampaign from "./components/pages/campaigns/NewLoyaltyCampaign";
import EditLoyaltyCampaign from "./components/pages/campaigns/EditLoyaltyCampaign";
import NewReferralCampaign from "./components/pages/campaigns/NewReferralCampaign";
import CreateSignupRuleTab from "./components/pages/campaigns/signup/CreateSignupRuleTab";
import EditReferralCampaign from "./components/pages/campaigns/EditReferralCampaign";
import ProfileSettings from "./components/pages/setting/ProfileSettings";
import FinancialAccount from "./components/pages/setting/FinancialAccount";
import GenerateAccessKeyAPI from "./components/pages/apiPlayground/apiPages/GenerateAccessKeyAPI";
import CustomerRegAPI from "./components/pages/apiPlayground/apiPages/CustomerRegAPI";
import TransactionAPI from "./components/pages/apiPlayground/apiPages/TransactionAPI";
import RedemptionAPI from "./components/pages/apiPlayground/apiPages/RedemptionAPI";
import HelpDetails from "./components/pages/HelpDetails";
import CustomerView from "./components/pages/customersList/CustomerView";

import ProtectedRoute from "./routes/ProtectedRoute";
import PublicRoute from "./routes/PublicRoute";
import AdminProtectedRoute from "./routes/AdminProtectedRoute";
import ThankyouResetPassword from "./components/auth/ThankyouResetPassword";
import ImportTransaction from "./components/pages/transactionsList/ImportTransaction";
import store from "./store";
import ChangePasswordSettings from "./components/pages/setting/ChangePasswordSettings";
import SampleCampaign from "./components/pages/SampleCampaign";
import NotFound from "./components/pages/NotFound";
import ContactUs from "./components/auth/ContactUs";
import TermAndCondition from "./components/layout/TermAndCondition";
import UploadingExistingDatas from "./components/pages/userJourney/UploadingExistingDatas";
import UploadLogo from "./components/pages/setting/UploadLogo";

import VoucherTemplate from "./components/voucher/VoucherTemplate";
import Voucher from "./components/voucher/Voucher";

import GiftCardVoucher from "./components/pages/giftCard/GiftCardVoucher";

import SaasLogin from "./components/auth/SaasLogin";

import Register from "./components/auth/Register";
import Contact from "./components/auth/Contact";
import GiftCardVoucher1 from "./components/pages/giftCard/GifrCardVoucher1";
import GiftCard1 from "./components/pages/giftCard/GiftCard1";
import AdminPublicRoute from "./routes/AdminPublicRoute";
import IntroStart from "./intro/index";
import IntroSidebar from "./intro/SidebarIntro";
import IntroBusinessGoalsSelection from "./intro/IntroBusinessGoalSelection";
import IntroSampleCampain from "./intro/SampleCampainIntro";
import IntroDashboard from "./intro/IntroDashboard";
import IntroNewLoyalty from "./intro/IntroNewLoyalty";
import IntroLPprogram from "./intro/LPprogramIntro";
import CustomerListIntro from "./intro/CustomerListIntro";
import TransactionsListIntro from "./intro/TransactionsListIntro";
import VouchersIntro from "./intro/VouchersIntro";
import IntroGiftCard1 from "./intro/GiftCardIntro";
import ProfileSettingIntro from "./intro/ProfileSettingIntro";
import IntroGenerateAccessKeyAPI from "./intro/IntroGenerateAccess";
import IntroAnalyticalReport from "./intro/RFMINtro";
import IntroGrowth from "./intro/GrowthProspectIntro";
import IntroCustomer from "./customerjourney";
import IntroRewardPoints from "./customerjourney/IntroRewardsPoints";
import IntroCustomerCampins from "./customerjourney/campains";
import IntroCustomerVocher from "./customerjourney/IntroCustomerVocher";
import IntroCustomerHistory from "./customerjourney/IntroHistory";
import IntroGiftCustomer from "./customerjourney/IntroGiftCustomer";
import IntroChooseReward from "./customerjourney/IntrochooseReward";
import IntroDeals from "./customerjourney/IntroTopDeals";
import IntroStoreId from "./customerjourney/IntroStoreId";
import IntroReddem from "./customerjourney/IntroReddem";
import IntroSucessRedeem from "./customerjourney/IntroSuccesfullRedeeem";
import CustomerGroup from "./components/pages/setting/CustomerGroup";
import WalletReport from "./components/pages/Report/WalletReport";
import CampaignReport from "./components/pages/Report/CampaignReport";
import SuccessPayment from "./components/layout/SuccessPayment";
import CanclePayment from "./components/layout/CanclePayment";
import CampaignData from "./components/pages/Report/CampaignData";
import Report from "./components/pages/Report/Report";
import AllVouchers from "./components/voucher/AllVouchers";
import GiftCard from "./components/pages/giftCard/GiftCard";
import AlertNotification from "./components/auth/AlertNotification";
import Theme1 from "./components/pages/Report/Theme1";
import Voucher1 from "./components/voucher/Voucher1";
import Theme from "./components/pages/Report/Theme";
import Integration from "./components/pages/setting/Integration";
import SendGrid from "./components/pages/setting/SendGrid";
import EmailReport from "./components/pages/setting/EmailReport";
import EmailDashboard from "./components/pages/userJourney/EmailDashboard";
import CustomerReview from "./components/pages/Report/CustomerReview";
import Offer from "./components/pages/offer/Offer";
import Membership from "./components/pages/membership/Membership";
import UploadInvoice from "./components/pages/uploadinvoice/UploadInvoice";
import WhatsappSend from "./components/pages/setting/WhatsappSend";
import Tiers from "./components/pages/tiers/Tiers";
// import PowerBIReport from "./components/pages/PowerBIReport";
import EmailTemplate from "./components/pages/setting/EmailTemplate";
import MembershipCard from "./components/pages/membership/MembershipCard";
import QRCodeTemplate from "./components/qrcode/QRCodeTemplate";
import QRCode from "./components/qrcode/QRCode";
import GetTier from "./components/pages/tiers/GetTier";
import AddMembership from "./components/pages/membership/AddMembership";
import EmployeeList from "./components/pages/Employee/EmployeeList";
import EmployeeView from "./components/pages/Employee/EmployeeView";
import EmployeeCustomerGroup from "./components/pages/Employee/EmployeeCustomerGroup";
import SchemaSetting from "./components/pages/Schema/SchemaSetting";
import TransactionSchema from "./components/pages/Schema/TransactionSchema";
import CreateSchema from "./components/pages/Schema/CreateSchema";
import CreateTransactionSchema from "./components/pages/Schema/CreateTransactionSchema";

const App = () => {
  const SidebarLayout = () =>
    localStorage.getItem("firstLogin") &&
    localStorage.getItem("firstLogin") !== "customer" ? (
      <IntroSidebar>
        <Outlet />
      </IntroSidebar>
    ) : localStorage.getItem("firstLogin") === "customer" ? (
      <Outlet />
    ) : (
      <Sidebar>
        <Outlet />
      </Sidebar>
    );
  useLayoutEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("intro") && !currentUrl.includes("customer")) {
      localStorage.setItem("firstLogin", "Y");
    } else {
      localStorage.setItem("firstLogin", "");
    }

    SidebarLayout();
  }, [window.location.href]);

  return (
    <Provider store={store}>
      {/* <SocketProvider > */}
      <BrowserRouter>
        <Routes>
          <Route element={<SidebarLayout />}>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <>
                    <>AnalyticalReport</>
                    <AnalyticalReport />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/upload-existing-data"
              element={
                <ProtectedRoute>
                  <>
                    <>UploadExistingData</>
                    <UploadExistingData />
                  </>
                </ProtectedRoute>
              }
            />

            <Route
              path="/uploading-existing-datas"
              element={
                <ProtectedRoute>
                  <>
                    <>UploadingExistingDatas</>
                    <UploadingExistingDatas />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/analytical-report"
              element={
                <ProtectedRoute>
                  <>
                    <>AnalyticalReport</>
                    <AnalyticalReport />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/business-goals-selection"
              element={
                <ProtectedRoute>
                  <>
                    <>BusinessGoalsSelection</>
                    <BusinessGoalsSelection />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/intro"
              element={
                <ProtectedRoute>
                  <IntroStart />
                </ProtectedRoute>
              }
            />
            <Route
              path="/intro/business-goals-selection"
              element={
                <ProtectedRoute>
                  <IntroBusinessGoalsSelection />
                </ProtectedRoute>
              }
            />
            <Route
              path="/intro/customer/rewards"
              element={
                <ProtectedRoute>
                  <IntroRewardPoints />
                </ProtectedRoute>
              }
            />
            <Route
              path="/intro/customer/campin"
              element={
                <ProtectedRoute>
                  <IntroCustomerCampins />
                </ProtectedRoute>
              }
            />
            <Route
              path="/intro/customer/history"
              element={
                <ProtectedRoute>
                  <IntroCustomerHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/intro/customer/voucher"
              element={
                <ProtectedRoute>
                  <IntroCustomerVocher />
                </ProtectedRoute>
              }
            />
            <Route
              path="/intro/customer/gift"
              element={
                <ProtectedRoute>
                  <IntroGiftCustomer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/intro/customer/choose_reward"
              element={
                <ProtectedRoute>
                  <IntroChooseReward />
                </ProtectedRoute>
              }
            />
            <Route
              path="/intro/customer/deals"
              element={
                <ProtectedRoute>
                  <IntroDeals />
                </ProtectedRoute>
              }
            />
            <Route
              path="/intro/customer/redeem"
              element={
                <ProtectedRoute>
                  <IntroReddem />
                </ProtectedRoute>
              }
            />
            <Route
              path="/intro/customer/store"
              element={
                <ProtectedRoute>
                  <IntroStoreId />
                </ProtectedRoute>
              }
            />
            <Route
              path="/intro/customer/sucess"
              element={
                <ProtectedRoute>
                  <IntroSucessRedeem />
                </ProtectedRoute>
              }
            />

            <Route
              path="/intro/sample-campaigns"
              element={
                <ProtectedRoute>
                  <IntroSampleCampain />
                </ProtectedRoute>
              }
            />
            <Route
              path="/intro/dashboard"
              element={
                <ProtectedRoute>
                  <IntroDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/intro/growth"
              element={
                <ProtectedRoute>
                  <IntroGrowth />
                </ProtectedRoute>
              }
            />
            <Route
              path="intro/my-campaigns/create-campaign"
              element={
                <ProtectedRoute>
                  <IntroNewLoyalty />
                </ProtectedRoute>
              }
            />
            <Route
              path="intro/my-campaigns"
              element={
                <ProtectedRoute>
                  <IntroLPprogram />
                </ProtectedRoute>
              }
            />
            <Route
              path="intro/customers-list"
              element={
                <ProtectedRoute>
                  <CustomerListIntro />
                </ProtectedRoute>
              }
            />
            <Route
              path="integration"
              element={
                <ProtectedRoute>
                  <Integration />
                </ProtectedRoute>
              }
            />
            <Route
              path="/emailSender"
              element={
                <ProtectedRoute>
                  <SendGrid />
                </ProtectedRoute>
              }
            />
            <Route
              path="/emailReport"
              element={
                <ProtectedRoute>
                  <EmailReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="intro/transactions-list"
              element={
                <ProtectedRoute>
                  <TransactionsListIntro />
                </ProtectedRoute>
              }
            />
            <Route
              path="intro/voucher-template"
              element={
                <ProtectedRoute>
                  <VouchersIntro />
                </ProtectedRoute>
              }
            />
            <Route
              path="intro/gift-card"
              element={
                <ProtectedRoute>
                  <IntroGiftCard1 />
                </ProtectedRoute>
              }
            />
            <Route
              path="intro/profile-settings"
              element={
                <ProtectedRoute>
                  <ProfileSettingIntro />
                </ProtectedRoute>
              }
            />

            <Route
              path="intro/generate-access-key-api"
              element={
                <ProtectedRoute>
                  <IntroGenerateAccessKeyAPI />
                </ProtectedRoute>
              }
            />
            <Route
              path="intro/analytics"
              element={
                <ProtectedRoute>
                  <IntroAnalyticalReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="intro/customer"
              element={
                <ProtectedRoute>
                  <IntroCustomer />
                </ProtectedRoute>
              }
            />
            <Route
              path="/growth-prospect"
              element={
                <ProtectedRoute>
                  <>
                    <>GrowthProspect</>
                    <GrowthProspect />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <>
                    <>Dashboard</>
                    <Dashboard />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/email-dashboard"
              element={
                <ProtectedRoute>
                  <>
                    <>Dashboard</>
                    <EmailDashboard />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-campaigns"
              element={
                <ProtectedRoute>
                  <>
                    <>Lprogram</>
                    <LProgram />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/voucher-template"
              element={
                <ProtectedRoute>
                  <>
                    <>VoucherTemplate</>
                    <VoucherTemplate />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/qrcode-template"
              element={
                <ProtectedRoute>
                  <>
                    <>QRCodeTemplate</>
                    <QRCodeTemplate />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/qr-code"
              element={
                <ProtectedRoute>
                  <>
                    <>QRCode</>
                    <QRCode />
                  </>
                </ProtectedRoute>
              }
            />
            {/* <Route
                            path="/powerBI-report"
                            element={
                                <ProtectedRoute>
                                    <>
                                        <>PowerBIReport</>
                                        <PowerBIReport />
                                    </>
                                </ProtectedRoute>
                            }
                        /> */}

            <Route
              path="/emailTemplate"
              element={
                <ProtectedRoute>
                  <>
                    <>EmailTemplate</>
                    <EmailTemplate />
                  </>
                </ProtectedRoute>
              }
            />

            <Route
              path="/whatsappMsg"
              element={
                <ProtectedRoute>
                  <>
                    <>WhatsappSend</>
                    <WhatsappSend />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/tiers"
              element={
                <ProtectedRoute>
                  <>
                    <>Tiers</>
                    <Tiers />
                  </>
                </ProtectedRoute>
              }
            />
             <Route
              path="/getTiers"
              element={
                <ProtectedRoute>
                  <>
                    <>GetTier</>
                    <GetTier />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/voucher1"
              element={
                <ProtectedRoute>
                  <>
                    <>VoucherTemplate</>
                    <Voucher />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/voucher"
              element={
                <ProtectedRoute>
                  <>
                    <>VoucherTemplate</>
                    <Voucher1 />
                  </>
                </ProtectedRoute>
              }
            />

            <Route
              path="/vouchers"
              element={
                <ProtectedRoute>
                  <>
                    <>AllVouchers</>
                    <AllVouchers />
                  </>
                </ProtectedRoute>
              }
            />
            {/* <Route
                            path="/gift-cards"
                            element={
                                <ProtectedRoute>
                                    <GiftCardVoucher />
                                </ProtectedRoute>
                            }
                        /> */}
            {/* <Route
                            path="/gift-cardss"
                            element={
                                <ProtectedRoute>
                                    <GiftCardVoucher1 />
                                </ProtectedRoute>
                            }
                        /> */}
            <Route
              path="/gift-card"
              element={
                <ProtectedRoute>
                  <>
                    <>GiftCard1</>
                    <GiftCard1 />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/sample-campaigns"
              element={
                <ProtectedRoute>
                  <>
                    <>BusinessGoalsSelection</>
                    <SampleCampaign />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/sample-campaigns/edit-campaign/"
              element={
                <ProtectedRoute>
                  <>
                    <>BusinessGoalsSelection</>
                    <EditLoyaltyCampaign />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-campaigns/create-campaign"
              element={
                <ProtectedRoute>
                  <>
                    <>Lprogram</>
                    <NewLoyaltyCampaign />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-campaigns/edit-campaign"
              element={
                <ProtectedRoute>
                  <>
                    <>Lprogram</>
                    <EditLoyaltyCampaign />
                  </>
                </ProtectedRoute>
              }
            />
            {/* <Route          
                            path="/referral-program/create-campaign"
                            element={
                                <ProtectedRoute>
                                    <NewReferralCampaign />
                                </ProtectedRoute>
                            }
                        /> */}
            {/* <Route
                            path="/referral-program/edit-campaign"
                            element={
                                <ProtectedRoute>
                                    <EditReferralCampaign />
                                </ProtectedRoute>
                            }
                        /> */}
            {/* <Route
                            path="/signup-program/create-campaign"
                            element={
                                <ProtectedRoute>
                                    <CreateSignupRuleTab />
                                </ProtectedRoute>
                            }
                        /> */}
            <Route
              path="/customers-list"
              element={
                <ProtectedRoute>
                  <>
                    <p>CustomersList</p>
                    <CustomersList />
                  </>
                </ProtectedRoute>
              }
            />
                 <Route
               path="/employee-view/:id"
              element={
                <ProtectedRoute>
                  <>
                    <p>EmployeeView</p>
                    <EmployeeView />
                  </>
                </ProtectedRoute>
              }
            />
              <Route
               path="/employee-group"
              element={
                <ProtectedRoute>
                  <>
                    <p>EmployeeCustomerGroup</p>
                    <EmployeeCustomerGroup />
                  </>
                </ProtectedRoute>
              }
            />
             <Route
              path="/employees-List"
              element={
                <ProtectedRoute>
                  <>
                    <p>EmployeeList</p>
                    <EmployeeList />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/offer"
              element={
                <ProtectedRoute>
                  <>
                    <p>Offer</p>
                    <Offer />
                  </>
                </ProtectedRoute>
              }
            />
            {/* <Route
                            path="/import-customers"
                            element={
                                <ProtectedRoute>
                                    <ImportCustomers />
                                </ProtectedRoute>
                            }
                        /> */}
            <Route
              path="/customer-view/:id"
              element={
                <ProtectedRoute>
                  <CustomerView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/transactions-list"
              element={
                <ProtectedRoute>
                  <>
                    <>TransactionsList</>
                    <TransactionsList />
                  </>
                </ProtectedRoute>
              }
            />
            {/* <Route
                            path="/import-transaction"
                            element={
                                <ProtectedRoute>
                                    <ImportTransaction />
                                </ProtectedRoute>
                            }
                        /> */}
            <Route
              path="/profile-settings"
              element={
                <ProtectedRoute>
                  <>
                    <>ProfileSettings</>
                    <ProfileSettings />
                  </>
                </ProtectedRoute>
              }
            />
             <Route
              path="/schema-setting"
              element={
                <ProtectedRoute>
                  <>
                    <>SchemaSetting</>
                    <SchemaSetting />
                  </>
                </ProtectedRoute>
              }
            />

<Route
              path="/create-Schema"
              element={
                <ProtectedRoute>
                  <>
                    <>CreateSchema</>
                    <CreateSchema />
                  </>
                </ProtectedRoute>
              }
            />

<Route
              path="/create-Transaction"
              element={
                <ProtectedRoute>
                  <>
                    <>CreateTransactionSchema</>
                    <CreateTransactionSchema />
                  </>
                </ProtectedRoute>
              }
            />

<Route
              path="/transaction-schema"
              element={
                <ProtectedRoute>
                  <>
                    <>TransactionSchema</>
                    <TransactionSchema />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/upload-logo"
              element={
                <ProtectedRoute>
                  <>
                    <>UploadLogo</>
                    <UploadLogo />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/customer-review"
              element={
                <ProtectedRoute>
                  <>
                    <>customerReview</>
                    <CustomerReview />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/membership"
              element={
                <ProtectedRoute>
                  <>
                    <>Membership</>
                    <Membership />
                  </>
                </ProtectedRoute>
              }
            />
              <Route
              path="/membership-list"
              element={
                <ProtectedRoute>
                  <>
                    <>AddMembership</>
                    <AddMembership />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/invoice"
              element={
                <ProtectedRoute>
                  <>
                    <>UploadInvoice</>
                    <UploadInvoice />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/membership-card"
              element={
                <ProtectedRoute>
                  <>
                    <>MembershipCard</>
                    <MembershipCard />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/change-password"
              element={
                <ProtectedRoute>
                  <ChangePasswordSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/wallet-report"
              element={
                <ProtectedRoute>
                  <WalletReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/campaign-report"
              element={
                <ProtectedRoute>
                  <CampaignReport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/report"
              element={
                <ProtectedRoute>
                  <Report />
                </ProtectedRoute>
              }
            />
            <Route
              path="/campaignreport"
              element={
                <ProtectedRoute>
                  <CampaignData />
                </ProtectedRoute>
              }
            />
            <Route
              path="/financial-account"
              element={
                <ProtectedRoute>
                  <>
                    <>FinancialAccount</>
                    <FinancialAccount />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/customer-group"
              element={
                <ProtectedRoute>
                  <>
                    <>CustomerGroup</>
                    <CustomerGroup />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/generate-access-key-api"
              element={
                <ProtectedRoute>
                  <>
                    <>GenerateAccessKeyAPI</>
                    <GenerateAccessKeyAPI />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/customer-registration-api"
              element={
                <ProtectedRoute>
                  <>
                    <>CustomerRegAPI</>
                    <CustomerRegAPI />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/transaction-api"
              element={
                <ProtectedRoute>
                  <>
                    <>TransactionAPI</>
                    <TransactionAPI />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/redemption-api"
              element={
                <ProtectedRoute>
                  <>
                    <>RedemptionAPI</>
                    <RedemptionAPI />
                  </>
                </ProtectedRoute>
              }
            />
            <Route
              path="/help-details"
              element={
                <ProtectedRoute>
                  <HelpDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contact-us"
              element={
                <ProtectedRoute>
                  <ContactUs />
                </ProtectedRoute>
              }
            />
            <Route
              path="/theme1"
              element={
                <ProtectedRoute>
                  <Theme1 />
                </ProtectedRoute>
              }
            />
            <Route
              path="/theme"
              element={
                <ProtectedRoute>
                  <Theme />
                </ProtectedRoute>
              }
            />

            <Route
              path="/success"
              element={
                <ProtectedRoute>
                  <SuccessPayment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/failed"
              element={
                <ProtectedRoute>
                  <CanclePayment />
                </ProtectedRoute>
              }
            />
            <Route
              path="*"
              element={
                <ProtectedRoute>
                  <NotFound />
                </ProtectedRoute>
              }
            />

            <Route
              path="/register"
              element={
                <AdminProtectedRoute>
                  <Register />
                </AdminProtectedRoute>
              }
            />
            <Route
              path="/alertNotification"
              element={
                <AdminProtectedRoute>
                  <AlertNotification />
                </AdminProtectedRoute>
              }
            />
            <Route
              path="/contact-us-query"
              element={
                <AdminProtectedRoute>
                  <Contact />
                </AdminProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/"
            element={
              <PublicRoute>
                <SignIn />
              </PublicRoute>
            }
          />
          <Route
            path="/signin"
            element={
              <PublicRoute>
                <SignIn />
              </PublicRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <PublicRoute>
                <SignUp />
              </PublicRoute>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <PublicRoute>
                <TermAndCondition />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/thankyou"
            element={
              <PublicRoute>
                <Thankyou />
              </PublicRoute>
            }
          />

          <Route
            path="/reset-thankyou"
            element={
              <PublicRoute>
                <ThankyouResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/verify-your-email"
            element={
              <PublicRoute>
                <VerifyYourEmail />
              </PublicRoute>
            }
          />
          <Route
            path="/contactus"
            element={
              <PublicRoute>
                <ContactUs />
              </PublicRoute>
            }
          />
          <Route
            path="/saas-admin"
            element={
              <AdminPublicRoute>
                <SaasLogin />
              </AdminPublicRoute>
            }
          />
        </Routes>
      </BrowserRouter>
      {/* </SocketProvider> */}
    </Provider>
  );
};

export default App;
