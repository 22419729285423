import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { getDate } from "../../../utils/helper";
import Switch from "react-switch";
import TextValidator from "../../partial/TextValidator";
import TextAreaValidator from "../../partial/TextAreaValidator";
import { connect } from "react-redux";
import { decrypt } from "../../../utils/encodingdecoding";
import { clearBusinessGoal, getBusinessGoal } from "../../../actions/businessGoal";
import SelectValidator from "../../partial/SelectValidator";
import { selectValueGetter } from "../../../utils/helper";
import CKEditor from "ckeditor4-react";

const CampaignDetailsTab = ({ getBusinessGoal, clearBusinessGoal, BusinessGoal, onChange, formateData, error, handleTabChange }) => {
    // const [toggle, setToggle] = useState(true);
    // const { data, onHandleChange } = props;
    const [options, setOptions] = useState();
    const [datas, setDatas] = useState();
    const [loader, setLoader] = useState(false);
    // const { onChange, formateData, error, handleTabChange } = props;

    const { name, endDateTime, startDateTime, description, status, businessGoal,tags,processingMode } = formateData;
    const onSubmit = () => {};

    const handleNext = () => {
        handleTabChange({ key: "Rules" });
    };

    useEffect(() => {
        if (Array.isArray(tags)) {
            const tagKeys = tags.map((item) => {
                if (typeof item === 'string' && item.includes(':')) {
                    return item.split(':')[0];
                } else {
                    return item.TagKey; 
                }
            }).join(', ');
            setDatas(tagKeys);
        } else {
            console.error('formData.tags is not an array:', tags);
        }
    }, [tags]);
    const [templateOptions] = useState([
        {
            value: "",
            label: "Select",
        },
      
        {
            value: "Promotion",
            label: "Promotion",
        },
        {
            value: "GoogleRating",
            label: "GoogleRating",
        },
        {
            value: "SocialMedia",
            label: "SocialMedia",
        },

    ]);
    const [Options] = useState([
        {
            value: "Transaction",
            label: "Transaction",
            type:0
        },
        {
            value:  "Triggered",
            label: "Triggered",
            type:1
        },
        {
            type:2,
            value: "TimeBased",
            label: "TimeBased",
        },

    ]);

    const [state, setState] = useState({
        data: [],
        goals: [],
    });

    const { goals, data } = state;

    useEffect(() => {
        fetchBusinessGoals();
    }, []); // eslint-disable-line

    useEffect(() => {
        setState({ ...state, data: BusinessGoal });
    }, [BusinessGoal]); // eslint-disable-line

    useEffect(() => {
        const industry = decrypt(localStorage.getItem("schemaName"));
        data.map((item) => {
            if (item.industry === industry) {
                setState({ ...state, goals: item.goals });
            }
        });
    }, [data]); // eslint-disable-line

    async function fetchBusinessGoals() {
        setLoader(true)
        const token = JSON.parse(localStorage.getItem("token_gen"));
        await clearBusinessGoal();
        await getBusinessGoal(token?.access_token);
        setLoader(false)
    }

    useEffect(() => {
        const temp = [];
        goals.map((goal) => {
            return temp.push({
                value: goal?.businessgoal,
                label: goal?.businessgoal,
            });
        });
        setOptions(temp);
    }, [goals]);

    return (
        <div>
             {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
        <ValidatorForm id="cpm_details" className="customForm" onSubmit={(e) => onSubmit(e)}>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <div className="form-group">
                        <label>Business Goal</label>
                        <SelectValidator placeholder="Select Business Goal" className="custom-ReactSelect bordered no____value" value={selectValueGetter(options, businessGoal)} selected={businessGoal} options={options} onChange={onChange} />
                        <span className="errorMessage">{error && error.errBusiness && error.errBusinessMsg}</span>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <div className="form-group">
                        <label>Campaign Name</label>
                        <TextValidator placeholder="Campaign Name" type="text" className="form-control no____value" value={name} name="name" onChange={onChange} />
                        <span className="errorMessage">{error && error.errName && error.errNameMsg}</span>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <div className="form-group">
                        <label>Start Date</label>

                        <TextValidator type="date" className="form-control no____value" min={getDate(new Date(), "YYYY-MM-DD")} value={getDate(startDateTime, "YYYY-MM-DD")} name="startDateTime" onChange={onChange} />
                        <span className="errorMessage">{error && error.errStartDate && error.errStartDateMsg}</span>
                        <span className="errorMessage">{error && error.errDateDiff && error.errDateDiffMsg}</span>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                    <div className="form-group">
                        <label>End Date</label>
                        <TextValidator type="date" className="form-control no____value" min={getDate(startDateTime, "YYYY-MM-DD")} value={getDate(endDateTime, "YYYY-MM-DD")} name="endDateTime" onChange={onChange} />
                        <span className="errorMessage">{error && error.errEndDate && error.errEndDateMsg}</span>
                    </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                        <label>Description</label>
                        <CKEditor
                                config={{ versionCheck: false }}
                                data={description}
                                onChange={onChange}
                            />
                    </div>
                </div>
                <div className="row">
                        <div className="col-md-4">
                            <div className="form-group withSwitch">
                                <label>
                                    Status:
                                    <Switch name="status" checked={status} onChange={(e) => onChange({ target: { name: 'status', value: e } })} onColor={status ? "#50BC14" : "#E50000"} onHandleColor="#50BC14" handleDiameter={30} uncheckedIcon={true} checkedIcon={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={48} className="react-switch" />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">

                                {/* <label>
                                    Promotion:
                                    <Switch
                                        name="tags"
                                        checked={tags && tags.length > 0}
                                        onChange={(e) => onChange({ target: { name: "tags", value: e } })}
                                        onColor={tags && tags.length > 0 ? "#50BC14" : "#E50000"}
                                        onHandleColor="#50BC14"
                                        handleDiameter={30}
                                        uncheckedIcon={true}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </label> */}
                                <label>Promotion</label>
                                   <SelectValidator placeholder="Select" 
                                   className="custom-ReactSelect bordered no____value"
                                    value={selectValueGetter(templateOptions, datas)}
                                     selected={tags} options={templateOptions} 
                                     onChange={(e) => onChange({ target: { name: "tags", value: e } })}/>
                            </div>
                        </div>
                        <div className="col-md-4">

                            <div className="form-group">

                                {/* <label>
                                    Promotion:
                                    <Switch
                                        name="tags"
                                        checked={tags && tags.length > 0}
                                        onChange={(e) => onChange({ target: { name: "tags", value: e } })}
                                        onColor={tags && tags.length > 0 ? "#50BC14" : "#E50000"}
                                        onHandleColor="#50BC14"
                                        handleDiameter={30}
                                        uncheckedIcon={true}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={20}
                                        width={48}
                                        className="react-switch"
                                    />
                                </label> */}
                                 <label>Processing Mode</label>
                                   <SelectValidator placeholder="Select" 
                                   className="custom-ReactSelect bordered no____value"
                                    value={selectValueGetter(Options, processingMode)}
                                     selected={processingMode} options={Options} 
                                     onChange={(e) => onChange({ target: { name: "processingMode", value: e } })}/>
                                    {console.log(error)}
                                      <span className="errorMessage">{error && error.errProcessing && error.errProcessingMsg}</span>
                            </div>
                        </div>
                    </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                    <div className="form-group"></div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="button-section">
                        <button type="button" className="btn btn-primary" onClick={handleNext}>
                            Next
                        </button>
                        {/* <button type="button" className="btn btn-outline-primary">
							Save as draft
						</button>
						<button type="button" className="btn btn-primary">
							Publish Offer
						</button> */}
                    </div>
                </div>
            </div>
        </ValidatorForm>
        </div>
    );
};

const mapStateToProps = (state) => ({
    BusinessGoal: state.BusinessGoal.data,

});
const mapDispatchToProps = {
    getBusinessGoal,
    clearBusinessGoal,

};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetailsTab);
