import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-form-validator-core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../../layout/Header";
import { backIcon2 } from "../../../Image/Index";
import TextValidator from "../../partial/TextValidator";
import SelectValidator from "../../partial/SelectValidator";
import TextAreaValidator from "../../partial/TextAreaValidator";
import { selectValueGetter } from "../../../utils/helper";
import { createCustomerSchema, updateCustomerSchema } from "../campaigns/services";
import { decrypt } from "../../../utils/encodingdecoding";

const CreateSchema = () => {
  const [uniqueArray, setuniqueArray] = useState();
  const [inputValue, setInputValue] = useState('');
  const [enumuratedValue, setEnumuratedValue] = useState([]);
  const [showEnumuration, setShowEnumuration] = useState();
  const location = useLocation();
  const [maxItems, setMaxItems] = useState();
  const [minItems, setMinItems] = useState();
  const [showArrayFields, setShowArrayFields] = useState(false);
  const [arrayFields, setArrayFields] = useState(false);
  const schemaData = location.state;
  const [arrayType, setArrayType] = useState();
  const [numberType, setNumberType] = useState();
  const [arrayItemsType, setArrayItemsType] = useState();
  const [fieldName, setFieldName] = useState("");
  const [fielddisplayname, setFielddisplayname] = useState("");
  const [fielddatatype, setFielddatatype] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [loader, setLoader] = useState(false);
  const [objectProperties, setObjectProperties] = useState([{ 
    key: "", 
    displayName: "", 
    type: "", 
    description: "",
    fielddatatype: "",
    numbertype: "",
    enumValues: "",
    arrayType: "",
    arrayItemsType: "",
    minItems: "",
    maxItems: "",
    uniqueItems: false
  }]);
  console.log(schemaData)
  const [toggles, setToggles] = useState({
    isRuleSupported: false,
    isIdent: false,
    isRequired: false,
    displayingrid: false,
    isPublic: false,
    isEncrypted: false,
    isPhone: false,
    isCardTemplate: false,
    isCustomerField: false,
    isTransactionDate: false,
    isTransactionMethodKey: false,
    isTransactionMethodValue: false,
    isTransactionChannel: false,
    isTransactionAmount: false,
    isSignUp: false,
    isSearchable: false,
    IsMerchant: false,
    isSso: false,
    isTransactionTime: false,
    isSourceName: false,
    isSourceType: false,
    isBankTransId: false,
    isRedeemFormField: false,
    isFormView: false,
  });
  const navigate = useNavigate();
  const [options] = useState([
    { value: "", label: "Select" },
    { value: "Array", label: "Array", type: "array" },
    { value: "Boolean", label: "Boolean", type: "boolean" },
    { value: "Enumuration", label: "Enumuration", type: "enumuration" },
    { value: "Object", label: "Object", type: "object" },
    { value: "Number", label: "Number", type: "number" },
    { value: "String", label: "String", type: "string" },
  ]);
  const [arrayOptions] = useState([
    { value: "", label: "None" },
    { value: "Number", label: "Number", type: "number" },
    { value: "String", label: "String", type: "string" },
  ]);
  const [numberOptions] = useState([
    { value: "", label: "None" },
    { value: "Integer", label: "Integer" },
    { value: "Number", label: "Number" },
  ]);
  const [propertyTypes] = useState([
    { value: "", label: "Select" },
    { value: "Number", label: "Number", type: "number" },
    { value: "String", label: "String", type: "string" },
    { value: "Boolean", label: "Boolean", type: "boolean" },
    { value: "Array", label: "Array", type: "array" },
    { value: "Enumuration", label: "Enumuration", type: "enumuration" },
  ]);

  const handleFieldNameChange = (e) => {
    setFieldName(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "fielddisplayname") {
      setFielddisplayname(value);
    } else if (name === "description") {
      setDescription(value);
    }
  };

  const handleChangeMax = (e) => {
    setMaxItems(e.target.value);
  };

  const handleChangemin = (e) => {
    setMinItems(e.target.value);
  };

  const handleChangeEnumuration = (e) => {
    const value = e.target.value;
    setInputValue(value);
    const newArray = value
      .split(',')
      .map(item => item.trim())
      .filter(item => item !== '');
    setEnumuratedValue(newArray);
  };

  const handleSelectChange = (selectedOption) => {
    setFielddatatype(selectedOption.value);
    setType(selectedOption.type);
    if (selectedOption.value === "Array") {
      setShowArrayFields(true);
      setShowEnumuration(false);
    } else if (selectedOption.value === "Enumuration") {
      setShowEnumuration(true);
      setShowArrayFields(false);
    } else if (selectedOption.value === "Object") {
      setShowArrayFields(false);
      setShowEnumuration(false);
    } else {
      setShowEnumuration(false);
      setShowArrayFields(false);
    }
  };

  const handleSelectNumber = (selectedOption) => {
    setNumberType(selectedOption.value);
  };

  const handleSelectArrayChange = (selectedOption) => {
    setArrayType(selectedOption.value);
    setArrayItemsType(selectedOption.type);
    if (selectedOption.value === "Number" || selectedOption.value === "String") {
      setArrayFields(true);
    } else {
      setArrayFields(false);
    }
  };

  const handlePropertyChange = (index, e) => {
    const { name, value } = e.target;
    const newProperties = [...objectProperties];
    newProperties[index].isRuleSupported = e.target.checked;
    newProperties[index][name] = value;
    setObjectProperties(newProperties);
  };

  const handlePropertyTypeChange = (index, selectedOption) => {
    const newProperties = [...objectProperties];
    newProperties[index].type = selectedOption.value;
    newProperties[index].fielddatatype = selectedOption.value;
    
    // Reset additional fields when type changes
    if (selectedOption.value !== "Array") {
      newProperties[index].arrayType = "";
      newProperties[index].arrayItemsType = "";
      newProperties[index].minItems = "";
      newProperties[index].maxItems = "";
      newProperties[index].uniqueItems = false;
    }
    if (selectedOption.value !== "Enumuration") {
      newProperties[index].enumValues = "";
    }
    
    setObjectProperties(newProperties);
  };

  const handlePropertyNumberType = (index, selectedOption) => {
    const newProperties = [...objectProperties];
    newProperties[index].numbertype = selectedOption.value;
    setObjectProperties(newProperties);
  };

  const handlePropertyArrayType = (index, selectedOption) => {
    const newProperties = [...objectProperties];
    newProperties[index].arrayType = selectedOption.value;
    newProperties[index].arrayItemsType = selectedOption.type;
    setObjectProperties(newProperties);
  };

  const handlePropertyEnumValues = (index, e) => {
    const newProperties = [...objectProperties];
    newProperties[index].enumValues = e.target.value;
    setObjectProperties(newProperties);
  };

  const handlePropertyMinItems = (index, e) => {
    const newProperties = [...objectProperties];
    newProperties[index].minItems = (e.target.value);
    setObjectProperties(newProperties);
  };

  const handlePropertyMaxItems = (index, e) => {
    const newProperties = [...objectProperties];
    newProperties[index].maxItems = (e.target.value);
    setObjectProperties(newProperties);
  };

  const handlePropertyUniqueItems = (index, e) => {
    const newProperties = [...objectProperties];
    newProperties[index].uniqueItems = e.target.checked;
    setObjectProperties(newProperties);
  };

  const addProperty = () => {
    setObjectProperties([...objectProperties, { 
      key: "", 
      displayName: "", 
      type: "", 
      description: "",
      fielddatatype: "",
      numbertype: "",
      enumValues: "",
      arrayType: "",
      arrayItemsType: "",
      minItems: "",
      maxItems: "",
      uniqueItems: false
    }]);
  };

  const removeProperty = (index) => {
    const newProperties = [...objectProperties];
    newProperties.splice(index, 1);
    setObjectProperties(newProperties);
  };

  const handleToggleChange = (toggleName) => {
    setToggles(prevState => ({
      ...prevState,
      [toggleName]: !prevState[toggleName],
    }));
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };


  useEffect(() => {
    if (schemaData) {
      const { 
        fielddisplayname, 
        description, 
        fielddatatype, 
        arraytype, 
        items, 
        StringTypeValidation,  
        minItems, 
        maxItems, 
        uniqueItems, 
        numberType,
        type, 
        properties,
        enum: enumValues,  
       
        ...otherToggles 
      } = schemaData.field;
      setNumberType(schemaData?.field?.numbertype)
      setFieldName(schemaData.key);
      setArrayType(arraytype || "");
      setMaxItems(maxItems);
      setMinItems(minItems);
      setuniqueArray(uniqueItems);
      setArrayItemsType(items?.type);
      setFielddisplayname(fielddisplayname || "");
      setDescription(description || "");
      setFielddatatype(fielddatatype || "");
      setType(type || "");
      if (fielddatatype === "Enumuration" && enumValues) {
        setEnumuratedValue(enumValues);
        setInputValue(enumValues.join(", "));
      }
      if (fielddatatype === "Object" && properties) {
        const props = Object.keys(properties).map(key => ({
          key,
          displayName: properties[key].fielddisplayname || "",
          type: properties[key].fielddatatype || "",
          description: properties[key].description || "",
          fielddatatype: properties[key].fielddatatype || "",
          numbertype: properties[key].numbertype || "",
          enumValues: properties[key].enum ? properties[key].enum.join(", ") : "",
          arrayType: properties[key].arraytype || "",
          arrayItemsType: properties[key].items?.type || "",
          minItems: properties[key].minItems || "",
          maxItems: properties[key].maxItems || "",
          isRuleSupported: properties[key].isRuleSupported || false,
          uniqueItems: properties[key].uniqueItems || false
        }));
        setObjectProperties(props.length > 0 ? props : [{ 
          key: "", 
          displayName: "", 
          type: "", 
          description: "", 
          fielddatatype: "", 
          numbertype: "",
          enumValues: "",
          arrayType: "",
          arrayItemsType: "",
          minItems: "",
          maxItems: "",
          uniqueItems: false
        }]);
      }
      setToggles(prevState => ({
        ...prevState,
        ...otherToggles,
      }));
    }
  }, [schemaData]);

  const displayEnumValue = enumuratedValue ? enumuratedValue.join(", ") : "";

  const handleRow = (array, rowSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += rowSize) {
      result.push(array.slice(i, i + rowSize));
    }
    return result;
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      [fieldName]: {
        fielddatatype: fielddatatype,
        StringTypeValidation: [],
        fielddisplayname: fielddisplayname,
        type: type,
        description: description,
        ...toggles,
      }
    };

    if (fielddatatype === "Array") {
      formData[fieldName].arraytype = arrayType;
      formData[fieldName].items = { type: arrayItemsType };
      formData[fieldName].minItems = minItems;
      formData[fieldName].maxItems = maxItems;
      formData[fieldName].uniqueItems = uniqueArray;
    } else if (fielddatatype === "Enumuration") {
      formData[fieldName].enum = enumuratedValue;
    } else if (fielddatatype === "Number") {
      formData[fieldName].numbertype = numberType;
    } else if (fielddatatype === "Object") {
      const propertiesObj = {};
      objectProperties.forEach(prop => {
        if (prop.key && prop.type) {
          propertiesObj[prop.key] = {
            fielddisplayname: prop.displayName,
            fielddatatype: prop.type,
            type: prop.type.toLowerCase(),
            description: prop.description,
            isRuleSupported: prop.isRuleSupported,
            isRequired: false
          };
          
          if (prop.type === "Number") {
            propertiesObj[prop.key].numbertype = prop.numbertype;
          } else if (prop.type === "Array") {
            propertiesObj[prop.key].arraytype = prop.arrayType;
            propertiesObj[prop.key].items = { type: prop.arrayItemsType };
            propertiesObj[prop.key].minItems = prop.minItems;
            propertiesObj[prop.key].maxItems = prop.maxItems;
            propertiesObj[prop.key].uniqueItems = prop.uniqueItems;
          } else if (prop.type === "Enumuration") {
            propertiesObj[prop.key].enum = prop.enumValues 
              ? prop.enumValues.split(',').map(item => item.trim()).filter(item => item !== '')
              : [];
          }
        }
      });
      formData[fieldName].properties = propertiesObj;
    }

    try {
      if (schemaData) {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        let id = decrypt(localStorage.getItem("Id"));
        const response = await updateCustomerSchema(token?.access_token, formData, id).then((res) => {
          navigate("/schema-setting");
        });
      } else {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        let id = decrypt(localStorage.getItem("Id"));
        const response = await createCustomerSchema(token?.access_token, formData, id).then((res) => {
          navigate("/schema-setting");
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setLoader(false);
    }
  };

  const handleStatus = (e) => {
    setuniqueArray(e.target.checked);
  };

  return (
    <>
      {loader && (
        <div className="mainPreloaderMain">
          <div className="mainPreloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <Header name={"Customer Schema"} />
      <div className="dashboard__content">
        <div className="container-fluid">
          <div className="dashboard__profileSettingsMain">
            <div className="dashboard__heading">
              <div className="dashboard__headingLeft">
                <div className="dashboard__headingBackLink">
                  <Link to="" onClick={() => navigate(-1)}>
                    <img src={backIcon2} alt="" />
                    <div className="dashboard__headingContent">
                      {schemaData ? <h3>Update Field</h3> : <h3>Add Field</h3>}
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="dashboard__profileSettingsBox">
              <ValidatorForm className="customForm customForm_2" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="form-group">
                      <label>
                        Field Name<span style={{ color: "red" }}>*</span>
                      </label>
                      {schemaData ? (
                        <TextValidator
                          maxLength="50"
                          className="form-control"
                          placeholder="Enter Field Name"
                          value={fieldName}
                          name="fieldName"
                          disabled
                          onChange={handleFieldNameChange}
                          validators={["required", "matchRegexp:^[a-zA-Z0-9@$_]*$"]}
                          errorMessages={[
                            "Please enter field Name",
                            "Only letters, numbers, @, $, and _ are allowed. Spaces are not allowed.",
                          ]}
                          onKeyPress={(e) => {
                            if (e.key === ' ') {
                              e.preventDefault();
                            }
                          }}
                          onPaste={(e) => {
                            const paste = e.clipboardData.getData('text');
                            if (paste.includes(' ')) {
                              e.preventDefault();
                          
                            }
                          }}
                        />
                      ) : (
                        <TextValidator
                          maxLength="50"
                          className="form-control"
                          placeholder="Enter Field Name"
                          value={fieldName}
                          name="fieldName"
                          onChange={handleFieldNameChange}
                          validators={["required", "matchRegexp:^[a-zA-Z0-9@$_]*$"]}
                          errorMessages={[
                            "Please enter field Name",
                            "Only letters, numbers, @, $, and _ are allowed. Spaces are not allowed.",
                          ]}
                          onKeyPress={(e) => {
                            if (e.key === ' ') {
                              e.preventDefault();
                            }
                          }}
                          onPaste={(e) => {
                            const paste = e.clipboardData.getData('text');
                            if (paste.includes(' ')) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="form-group">
                      <label>
                        Field Display Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <TextValidator
                        maxLength="50"
                        className="form-control"
                        name="fielddisplayname"
                        placeholder="Enter Field Display Name"
                        value={fielddisplayname}
                        onChange={handleChange}
                        validators={["required", "matchRegexp:^[a-zA-Z0-9@$ ]*$"]}
                        errorMessages={[
                          "Please enter field display name",
                          "Only letters, numbers, @, and $ are allowed.",
                        ]}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                    <div className="form-group">
                      <label>
                        Field Data Type<span style={{ color: "red" }}>*</span>
                      </label>
                      <SelectValidator
                        placeholder="Select Type"
                        className="custom-ReactSelect bordered"
                        name="fielddatatype"
                        options={options}
                        value={selectValueGetter(options, fielddatatype) || ""}
                        onChange={handleSelectChange}
                        validators={["required"]}
                        errorMessages={["Please enter field data type"]}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Description</label>
                      <TextAreaValidator
                        className="form-control"
                        placeholder="Enter Description"
                        maxLength="500"
                        name="description"
                        value={description}
                        onChange={handleDescriptionChange}
                      />
                    </div>
                  </div>
                </div>

                {fielddatatype === "Enumuration" && (
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group">
                        <label>
                          Enumerated values<span style={{ color: "red" }}>*</span>
                        </label>
                        <TextValidator
                          name="enum"
                          className="form-control"
                          value={inputValue}
                          onChange={handleChangeEnumuration}
                          placeholder="Enter Enumuration Value"
                          validators={['required']}
                          errorMessages={['Please enter enumuration value']}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {fielddatatype === "Number" && (
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group">
                        <label>
                          Number Type<span style={{ color: "red" }}>*</span>
                        </label>
                        <SelectValidator
                          placeholder="Select Type"
                          className="custom-ReactSelect bordered"
                          name="numbertype"
                          options={numberOptions}
                          value={selectValueGetter(numberOptions, numberType) || ""}
                          onChange={handleSelectNumber}
                          validators={["required"]}
                          errorMessages={["Please enter Number type"]}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {fielddatatype === "Array" && (
                  <div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                      <div className="form-group">
                        <label>
                          Array Type<span style={{ color: "red" }}>*</span>
                        </label>
                        <SelectValidator
                          placeholder="Select Type"
                          className="custom-ReactSelect bordered"
                          name="arrayType"
                          options={arrayOptions}
                          value={selectValueGetter(arrayOptions, arrayType) || ""}
                          onChange={handleSelectArrayChange}
                          validators={["required"]}
                          errorMessages={["Please enter Array type"]}
                        />
                      </div>
                    </div>
                    {(arrayType === "Number" || arrayType === "String") && (
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                          <div className="form-group">
                            <label>
                              Min Array Items<span style={{ color: "red" }}>*</span>
                            </label>
                            <TextValidator
                              maxLength="50"
                              className="form-control"
                              name="minItems"
                              placeholder="Enter Min Items"
                              value={minItems}
                              type="number"
                              onChange={handleChangemin}
                              validators={["required"]}
                              errorMessages={["Please Enter Min Items"]}
                            />
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                          <div className="form-group">
                            <label>
                              Max Array Items<span style={{ color: "red" }}>*</span>
                            </label>
                            <TextValidator
                              maxLength="50"
                              className="form-control"
                              name="maxItems"
                              placeholder="Enter Max Items"
                              value={maxItems}
                              onChange={handleChangeMax}
                              type="number"
                              validators={["required"]}
                              errorMessages={["Please enter Max Items"]}
                            />
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                          <div className="person" style={{ display: "flex", alignItems: "center", width: "100%", marginTop: "30px" }}>
                            <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0", color: "black" }}>
                              Is array unique?
                            </p>
                            <div>
                              <label className="toggle">
                                <input type="checkbox" name="status" checked={uniqueArray} onChange={handleStatus} />
                                <span className="slider"></span>
                                <span className="labels" data-on="True" data-off="False"></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
   {fielddatatype && fielddatatype !== "Object" ? (
    <div>
  {handleRow(
    Object.keys(toggles).filter((toggleKey) =>
      ![
        "displayingrid",
        "isTransactionMethodKey",
        "isTransactionMethodValue",
        "isTransactionChannel",
        "IsMerchant",
        "isTransactionTime",
        "isSourceName",
        "isSourceType",
        "isBankTransId",
      ].includes(toggleKey)
    ),
    4
  ).map((chunk, index) => (
    <div key={index} className="row">
      {chunk.map((toggleKey) => (
        <div key={toggleKey} className="col-sm-12 col-md-3 mt-3">
          <div className="person" style={{ display: "flex", alignItems: "center" }}>
            <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0", color: "black" }}>
              {toggleKey === "isFormView" 
                ? "Display In Page" 
                : toggleKey.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
            </p>
            <div>
              <label className="toggle">
                <input
                  type="checkbox"
                  checked={toggles[toggleKey]}
                  onChange={() => handleToggleChange(toggleKey)}
                />
                <span className="slider"></span>
                <span className="labels" data-on="true" data-off="false"></span>
              </label>
            </div>
          </div>
        </div>
      ))}
    </div>
  ))}
</div>
) : (
  fielddatatype === "Object" && (
    <div className="row">
      <div className="col-sm-12 col-md-4 ">
        <div className="person" style={{ display: "flex", alignItems: "center" }}>
          <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0", color: "black" }}>
            Is Encrypted
          </p>
          <div>
            <label className="toggle">
              <input
                type="checkbox"
                checked={toggles.isEncrypted}
                onChange={() => handleToggleChange("isEncrypted")}
              />
              <span className="slider"></span>
              <span className="labels" data-on="true" data-off="false"></span>
            </label>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 ">
        <div className="person" style={{ display: "flex", alignItems: "center" }}>
          <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0", color: "black" }}>
            Is Public
          </p>
          <div>
            <label className="toggle">
              <input
                type="checkbox"
                checked={toggles.isPublic}
                onChange={() => handleToggleChange("isPublic")}
              />
              <span className="slider"></span>
              <span className="labels" data-on="true" data-off="false"></span>
            </label>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-md-4 ">
        <div className="person" style={{ display: "flex", alignItems: "center" }}>
          <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0", color: "black" }}>
            Display In Page
          </p>
          <div>
            <label className="toggle">
              <input
                type="checkbox"
                checked={toggles.isFormView}
                onChange={() => handleToggleChange("isFormView")}
              />
              <span className="slider"></span>
              <span className="labels" data-on="true" data-off="false"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
)}
                {fielddatatype === "Object" && (
                  <div>
                  <div className="row mb-2">
                      <div className="col-sm-12">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={addProperty}
                        >
                          Add Property
                        </button>
                      </div>
                    </div>
                    {objectProperties.map((property, index) => (
                      <div key={index} style={{ marginBottom: "20px", padding: "15px", border: "1px solid #eee", borderRadius: "5px" }}>
                        <div className="row" style={{justifyContent:"end"}}>
                          <div className="col-sm-12 col-md-6 col-lg-1" style={{ display: "flex", alignItems: "end" }}>
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => removeProperty(index)}
                              disabled={objectProperties.length === 1}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>
                                Property Key<span style={{ color: "red" }}>*</span>
                              </label>
                              <TextValidator
                                maxLength="50"
                                className="form-control"
                                placeholder="Enter Property Key"
                                value={property.key}
                                name="key"
                                onChange={(e) => handlePropertyChange(index, e)}
                                validators={["required", "matchRegexp:^[a-zA-Z0-9@$ ]*$"]}
                                errorMessages={[
                                  "Please enter property key",
                                  "Only letters, numbers, @, and $ are allowed.",
                                ]}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>
                                Display Name<span style={{ color: "red" }}>*</span>
                              </label>
                              <TextValidator
                                maxLength="50"
                                className="form-control"
                                placeholder="Enter Display Name"
                                value={property.displayName}
                                name="displayName"
                                onChange={(e) => handlePropertyChange(index, e)}
                                validators={["required"]}
                                errorMessages={["Please enter display name"]}
                              />
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="form-group">
                              <label>
                                Type<span style={{ color: "red" }}>*</span>
                              </label>
                              <SelectValidator
                                placeholder="Select Type"
                                className="custom-ReactSelect bordered"
                                name="type"
                                options={propertyTypes}
                                value={selectValueGetter(propertyTypes, property.type) || ""}
                                onChange={(selectedOption) => handlePropertyTypeChange(index, selectedOption)}
                                validators={["required"]}
                                errorMessages={["Please select property type"]}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label>Description</label>
                              <TextAreaValidator
                                className="form-control"
                                placeholder="Enter Description"
                                value={property.description}
                                name="description"
                                onChange={(e) => handlePropertyChange(index, e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
      <div className="col-sm-12 col-md-3 mt-3">
        <div className="person" style={{ display: "flex", alignItems: "center" }}>
          <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0", color: "black" }}>
          SupportRules
          </p>
          <div>
            <label className="toggle">
              <input
                type="checkbox"
                checked={property.isRuleSupported}
                onChange={(e) => handlePropertyChange(index, e)}
              />
              <span className="slider"></span>
              <span className="labels" data-on="true" data-off="false"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
                        {/* Enumuration Fields */}
                        {property.type === "Enumuration" && (
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                              <div className="form-group">
                                <label>
                                  Enumerated values<span style={{ color: "red" }}>*</span>
                                </label>
                                <TextValidator
                                  name="enumValues"
                                  className="form-control"
                                  value={property.enumValues}
                                  onChange={(e) => handlePropertyEnumValues(index, e)}
                                  placeholder="Enter Enumuration Value"
                                  validators={['required']}
                                  errorMessages={['Please enter enumuration values']}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        
                        {/* Array Fields */}
                        {property.type === "Array" && (
                          <div>
                            <div className="row">
                              <div className="col-sm-12 col-md-6 col-lg-4">
                                <div className="form-group">
                                  <label>
                                    Array Type<span style={{ color: "red" }}>*</span>
                                  </label>
                                  <SelectValidator
                                    placeholder="Select Type"
                                    className="custom-ReactSelect bordered"
                                    name="arrayType"
                                    options={arrayOptions}
                                    value={selectValueGetter(arrayOptions, property.arrayType) || ""}
                                    onChange={(selectedOption) => handlePropertyArrayType(index, selectedOption)}
                                    validators={["required"]}
                                    errorMessages={["Please select array type"]}
                                  />
                                </div>
                              </div>
                            </div>
                            
                            {(property.arrayType === "Number" || property.arrayType === "String") && (
                              <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                  <div className="form-group">
                                    <label>
                                      Min Items<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <TextValidator
                                      maxLength="50"
                                      className="form-control"
                                      name="minItems"
                                      placeholder="Enter Min Items"
                                      value={property.minItems}
                                      type="number"
                                      onChange={(e) => handlePropertyMinItems(index, e)}
                                      validators={["required"]}
                                      errorMessages={["Please enter min items"]}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                  <div className="form-group">
                                    <label>
                                      Max Items<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <TextValidator
                                      maxLength="50"
                                      className="form-control"
                                      name="maxItems"
                                      placeholder="Enter Max Items"
                                      type="number"
                                      value={property.maxItems}
                                      onChange={(e) => handlePropertyMaxItems(index, e)}
                                      validators={["required"]}
                                      errorMessages={["Please enter max items"]}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                  <div className="person" style={{ display: "flex", alignItems: "center", width: "100%", marginTop: "30px" }}>
                                    <p className="voucher" style={{ display: "flex", width: "100%", marginBottom: "0", color: "black" }}>
                                      Is array unique?
                                    </p>
                                    <div>
                                      <label className="toggle">
                                        <input 
                                          type="checkbox" 
                                          name="uniqueItems" 
                                          checked={property.uniqueItems} 
                                          onChange={(e) => handlePropertyUniqueItems(index, e)} 
                                        />
                                        <span className="slider"></span>
                                        <span className="labels" data-on="True" data-off="False"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                        
                        {/* Number Type Field */}
                        {property.type === "Number" && (
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-4">
                              <div className="form-group">
                                <label>
                                  Number Type<span style={{ color: "red" }}>*</span>
                                </label>
                                <SelectValidator
                                  placeholder="Select Type"
                                  className="custom-ReactSelect bordered"
                                  name="numbertype"
                                  options={numberOptions}
                                  value={selectValueGetter(numberOptions, property.numbertype) || ""}
                                  onChange={(selectedOption) => handlePropertyNumberType(index, selectedOption)}
                                  validators={["required"]}
                                  errorMessages={["Please select number type"]}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        
                     
                        
                       
                      </div>
                    ))}
                    
                   
                  </div>
                )}

           

                <div className="row mt-2">
                  <div className="col-sm-12">
                    <div className="button-section">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </ValidatorForm>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSchema;