import React, { useEffect, useState } from 'react'
import Header from '../../layout/Header';
import { getCustomerGroupList } from '../../../actions/campaigns';
import { getDate } from '../../../utils/helper';
import { Link } from 'react-router-dom';
import Paginated from './Paginated';
import { COLUMNS } from './column';
import { GetTierData } from '../../../actions/auth';
import { decrypt } from '../../../utils/encodingdecoding';
import { Button } from 'react-bootstrap';

function GetTier() {
    const [loader, setLoader] = useState(false);
    const [response, setResponse] = useState()
    const [setList, setFormList] = useState([])

    const updateData = () => {
        setLoader(true)
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = decrypt(localStorage.getItem("Id"))
        GetTierData(token?.access_token, id).then((res) => {
            setResponse(res?.data)
            setLoader(false)
        });
    }

    useEffect(() => {
        updateData()
    }, [])

    useEffect(() => {
        const tempData = response;
        if (tempData) {
            tempData?.map((val, ind) => {
                val.name = val.name;
                val.code = val.code;
                val.priority = val.level;
                val.status = (val.status === true ? "True" : "False")
                val.action = (
                    <Link to="/tiers" state={val?.code} className="edit_btn" csvalue="Edit">
                        <i className="fa-solid fa-pen"></i>
                        <div className="show_div">Edit</div>
                    </Link>
                );
                return val;
            });
        }
        setFormList(tempData);
    }, [response]);




    return (
        <div>
            <Header name={"Tier"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className='dashboard__content'>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="dashboard__heading">
                        <div className="dashboard__headingLeft">
                            <div className="dashboard__headingContent">
                                <Link to="/tiers">
                                    <Button id="voucher" className="btn btn-primary btn-lg">
                                        Create Tier
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dashboard__customersCard">
                    <div className="dashboard__header">
                        <div className="dashboard__left">
                            <h4 id='transction_list'>Tier List</h4>
                        </div>
                    </div>
                    <div className="dashboard__body">
                        <Paginated data={setList ? setList : []} columns={COLUMNS} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetTier

