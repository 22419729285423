import React, { useEffect, useState } from "react";
import Header from "../layout/Header";
import { getDate } from "../../utils/helper";
import ProgramsCard from "../partial/ProgramsCard";
import { getSampleCampaigns, clearCampaigns } from "../../actions/campaigns";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Page from "./Pagination";
import { backIcon2, email_marketing } from "../../Image/Index";
import { decrypt } from "../../utils/encodingdecoding";

const SampleCampaign = ({ getSampleCampaigns, clearCampaigns, Campaigns }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [data, setData] = useState(" ");
    // eslint-disable-next-line
    const [currentPage, setCurrentPage] = useState(0);

    // eslint-disable-next-line
    const [hasNextPage, setHasNextPage] = useState(false);
    // eslint-disable-next-line
    const [hasPreviousPage, setHasPreviousPage] = useState(false);
    // eslint-disable-next-line
    const [total, setTotal] = useState(0);
    const [loader, setLoader] = useState(true);
    const pageSize = 6;
    const [showButton, setShowButton] = useState(false);
    const pageCount = Math.ceil(total / pageSize);

    useEffect(() => {
        fetch();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (!Campaigns?.show) {
            taggedSCamp();
        }
    }, [Campaigns?.show]); // eslint-disable-line

    const fetch = async () => {
        setLoader(true);
        await clearCampaigns();
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = localStorage.getItem("Id");
        let industry = localStorage.getItem("schemaName");
        await getSampleCampaigns(token?.access_token, id, industry);
        setLoader(false);
    };

    const taggedSCamp = async () => {
        var industry = localStorage.getItem("schemaName");
        let tempTagData = [];
        let temp = location?.state?.length ? location?.state : location?.state?.tags;
        await Campaigns.data.forEach((item) => {
            temp?.length
                ? temp?.forEach((tag) => {
                      item?.internalTags?.forEach((e) => {
                          if (tag === e) {
                              tempTagData.push(item);
                          }
                      });
                  })
                : item?.internalTags?.forEach((e) => {
                      if (`industry:${decrypt(industry).toLocaleLowerCase()}` === e.toLocaleLowerCase()) {
                          tempTagData.push(item);
                      }
                  });
        });
        await campaign(tempTagData);
    };

    const campaign = async (tagData) => {
        setHasNextPage(tagData?.length >= pageSize);
        setHasPreviousPage(currentPage > 0);
        setTotal(tagData?.length);
        if (tagData?.length > 0) {
            setData(tagData.reverse());
            setShowButton(true);
        } else {
            setShowButton(false);
            setData([]);
        }
    };

    return (
        <>
            <Header name={"Sample Campaigns"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__heading">
                        <div className="dashboard__headingLeft">
                            <div className="dashboard__headingBackLink">
                                <Link to="" onClick={() => navigate(-1)}>
                                    <img src={backIcon2} alt="" />
                                    <div className="dashboard__headingContent">
                                        <h3>Back</h3>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="dashboard__heading">
                                <div className="dashboard__headingLeft">
                                    <div className="dashboard__headingContent">
                                        <h3 id="sample_comp">Sample Campaigns</h3>
                                        <p>Set how your users will earn points</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!Array.isArray(data) ? (
                            ""
                        ) : data?.length > 0 ? (
                            data.slice(currentPage * pageSize, (currentPage + 1) * pageSize).map((item, index) => (
                                <div className="col-sm-12 col-md-6 col-lg-4" key={item.code + index}>
                                    <ProgramsCard path="/sample-campaigns/edit-campaign" id={item.code} data={item} index={currentPage * 6 + index} heading={item.name} startDate={getDate(item.startDateTime, "MM-DD-YYYY")} endDate={getDate(item.endDateTime, "MM-DD-YYYY")} text={item?.description} campaignCode={item.code} sample={true} tags={location.state} firstData={index === 0 ? true : false} value={item?.earningRule?.rules[0]?.data[0]?.Value} earningvalue={item?.earningRule?.rules[0]?.data[0]?.RedeemType} />
                                </div>
                            ))
                        ) : (
                            <div className="no_sample_campaign">
                                <img src={email_marketing} alt="email" />
                                <h4>No sample campaign available for demonstration at present.</h4>
                            </div>
                        )}
                        {showButton && (
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="dashboard__paginationBox">
                                    <Page currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} hasNextPage={hasNextPage} hasPreviousPage={hasPreviousPage} value={5} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    Campaigns: state.Campaigns,
});
const mapDispatchToProps = {
    getSampleCampaigns,
    clearCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleCampaign);
