import { Link } from "react-router-dom";
import { useState } from "react";
export const DOCUMENT_COLUMN = [
  {
    Header: "Document Name",
    Footer: "Document Name",
    accessor: "mediaName",
    sticky: "left",
  },
  {


    Header: "Document Link",
    Footer: "Document Link",
    accessor: "blobUrl",
    Cell: ({ value }) => {
      const [copied, setCopied] = useState(false);
    
      const handleCopy = () => {
        navigator.clipboard.writeText(value);
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      };
    
      return (
        <>
          <Link to={value}>
            <i className="fa-solid fa-download"></i>
          </Link>
          <Link 
            onClick={handleCopy} 
            style={{ marginLeft: "10px", cursor: "pointer" }}
          >
            <i className="fa-solid fa-copy"></i>
          </Link>
          {copied && <span style={{ marginLeft: "10px", color: "black" }}>Copied</span>}
        </>
      );
    }
    
    
  },
  {
    Header: "Delete",
    Footer: "Delete",
    accessor: "delete",
    // Cell: ({ value }) => (
    //     <Link to={value}>
    //     <i class="fa-solid fa-x"></i>
    //     </Link>
    // ),

    // Cell: ({ value }) => {
    // 	return format(new Date(value), "dd/MM/yyyy");
    // },
  },
];
