import { getNewAuthToken, isTokenExpired } from "../utils";
import { baseURL } from "../utils/url";
import { CLEAR_CAMPAIGNS, CREATE_SAMPLE_CAMPAIGNS, GET_CAMPAIGNS, GET_SAMPLE_CAMPAIGNS, UPDATE_SAMPLE_CAMPAIGN, GET_CAMPAIGN } from "./type";

export const getCampaigns = (token, id) => async (dispatch) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getCampaigns?id=" + id, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        fetchResponse
            .json()
            .then((res) => {
                if (res.length > 0) {
                    dispatch({
                        type: GET_CAMPAIGNS,
                        payload: res,
                    });

                    return res;
                } else {
                    dispatch({
                        type: GET_CAMPAIGNS,
                        payload: "no_cmp",
                    });
                }
            })
            .catch(() => {
                return null;
            });
    } catch (error) {
        return error;
    }
};

export const getCampaign = (token, id) => async (dispatch) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getCampaign?id=" + id, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        fetchResponse
            .json()
            .then((res) => {
                if (res.errors == null) {
                    if (res.data) {
                        dispatch({
                            type: GET_CAMPAIGN,
                            payload: res.data,
                        });
                        return res.data;
                    }
                } else {
                    return null;
                }
            })
            .catch(() => {
                return null;
            });
    } catch (error) {
        return error;
    }
};

export const getSampleCampaigns = (token, id, industry) => async (dispatch) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getsamplecampaigns?id=" + id + "&industry=" + industry, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        fetchResponse
            .json()
            .then((res) => {
                if (res.length >= 0) {
                    dispatch({
                        type: GET_SAMPLE_CAMPAIGNS,
                        payload: res,
                        show: false,
                    });
                    return res;
                } else {
                    return null;
                }
            })
            .catch(() => {
                return null;
            });
    } catch (error) {
        return error;
    }
};

export const createsample = (token, data) => async (dispatch) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "campaigns", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        fetchResponse
            .json()
            .then((res) => {
                if (res.errors == null) {
                    dispatch({
                        type: CREATE_SAMPLE_CAMPAIGNS,
                        payload: res,
                    });

                    return res;
                } else {
                    return null;
                }
            })
            .catch(() => {
                return null;
            });
    } catch (error) {
        return error;
    }
};

export const getUserJourneyCampaigns = (token, data) => async (dispatch) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "sampleCampaign", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        fetchResponse
            .json()
            .then((res) => {
                if (res.length >= 0) {
                    dispatch({
                        type: GET_CAMPAIGNS,
                        payload: res,
                    });
                    return res;
                } else {
                    return null;
                }
            })
            .catch(() => {
                return null;
            });
    } catch (error) {
        return error;
    }
};

export const getEmailTemplatePlaceholder = async (token) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getEmailTemplatePlaceholder", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();

        // fetchResponse
        //     .json()
        //     .then((res) => {
        //         if (res.length >= 0) {
        //             // dispatch({
        //             //     type: GET_CAMPAIGNS,
        //             //     payload: res,
        //             // });
        //             console.log(res)
        //             return fetchResponse
        //             .json();
        //         } else {
        //             return null;
        //         }
        //     })
        //     .catch(() => {
        //         return null;
        //     });
    } catch (error) {
        return error;
    }
};

export const getCustomerGroupList = async (token) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getCustomerGroupList", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
        // fetchResponse
        //     .json()
        //     .then((res) => {
        //         if (res.length >= 0) {
        //             // dispatch({
        //             //     type: GET_CAMPAIGNS,
        //             //     payload: res,
        //             // });
        //             console.log(res)
        //             return fetchResponse
        //             .json();
        //         } else {
        //             return null;
        //         }
        //     })
        //     .catch(() => {
        //         return null;
        //     });
    } catch (error) {
        return error;
    }
};
export const getCustomerGroupById = async (token, id) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getCustomerGroupId?Id=" + id, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
    } catch (error) {
        return error;
    }
};

export const getEmailTemplate = async (token) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getEmailTemplate", {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
        // fetchResponse
        //     .json()
        //     .then((res) => {
        //         if (res.length >= 0) {
        //             // dispatch({
        //             //     type: GET_CAMPAIGNS,
        //             //     payload: res,
        //             // });
        //             console.log(res)
        //             return fetchResponse
        //             .json();
        //         } else {
        //             return null;
        //         }
        //     })
        //     .catch(() => {
        //         return null;
        //     });
    } catch (error) {
        return error;
    }
};
export const postCombineCommunicationFlow = async (token, body) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "postCombineCommunicationFlow", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
        // fetchResponse
        //     .json()
        //     .then((res) => {
        //         if (res.length >= 0) {
        //             // dispatch({
        //             //     type: GET_CAMPAIGNS,
        //             //     payload: res,
        //             // });
        //             console.log(res)
        //             return fetchResponse
        //             .json();
        //         } else {
        //             return null;
        //         }
        //     })
        //     .catch(() => {
        //         return null;
        //     });
    } catch (error) {
        return error;
    }
};

export const getCommunicationFlowsList = async (token, campaignCode) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + "getCommunicationFlowsList?campaignCode=" + campaignCode, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        return await fetchResponse.json();
        // fetchResponse
        //     .json()
        //     .then((res) => {
        //         if (res.length >= 0) {
        //             // dispatch({
        //             //     type: GET_CAMPAIGNS,
        //             //     payload: res,
        //             // });
        //             console.log(res)
        //             return fetchResponse
        //             .json();
        //         } else {
        //             return null;
        //         }
        //     })
        //     .catch(() => {
        //         return null;
        //     });
    } catch (error) {
        return error;
    }
};

export const updateCommunicationFlow = async (token, body, id) => {
    const valid = await isTokenExpired(token);
    try {
        if (!valid)
            await getNewAuthToken().then((res) => {
                localStorage.setItem("token_gen", JSON.stringify(res));
                token = res?.access_token;
            });
        const fetchResponse = await fetch(baseURL + `updateCommunicationFlow?id=${id}`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        return await fetchResponse.json();
        // fetchResponse
        //     .json()
        //     .then((res) => {
        //         if (res.length >= 0) {
        //             // dispatch({
        //             //     type: GET_CAMPAIGNS,
        //             //     payload: res,
        //             // });
        //             console.log(res)
        //             return fetchResponse
        //             .json();
        //         } else {
        //             return null;
        //         }
        //     })
        //     .catch(() => {
        //         return null;
        //     });
    } catch (error) {
        return error;
    }
};

export const updateSampleCampaign = (data) => (dispatch) => {
    dispatch({ type: UPDATE_SAMPLE_CAMPAIGN, payload: data });
};

export const clearCampaigns = () => async (dispatch) => {
    dispatch({ type: CLEAR_CAMPAIGNS });
};
