import React, { useState, useEffect, memo, useMemo } from "react";
import Header from "../layout/Header";
import Paginated from "./customersList/Paginated";
import { Link } from "react-router-dom";
import { COLUMNS } from "./customersList/column";
// eslint-disable-next-line
import { importIcon } from "../../Image/Index";
import { connect } from "react-redux";
import { getCustomer, clearCustomer } from "../../actions/customer";
import { decrypt } from "../../utils/encodingdecoding";
import { COLUMNS1 } from "./customersList/column1";
import { getAllCustomer, getSchemaList } from "../../actions/auth";

const CustomersList = memo(({ getCustomer, Customer, clearCustomer }) => {
    const [data, setData] = useState([]);
    const [customerCount, setCustomerCount] = useState();
    const [response, setResponse] = useState([]);
    const [shemaData, setSchemaData] = useState()
    const [loader, setLoader] = useState(true);
    let id = decrypt(localStorage.getItem("Id"))

    useEffect(() => {
        let tempPageIndex = JSON.parse(localStorage.getItem("CusPageIndex"));
        if (tempPageIndex === 1) {
            fetch();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setResponse(Customer?.data)
    }, [Customer])

    const updateData = () => {
        setLoader(true)
        const token = JSON.parse(localStorage.getItem("token_gen"));
        getSchemaList(token?.access_token).then((res) => {
            setSchemaData(res?.schema?.properties)
      
        });
    }

    useEffect(() => {
        updateData()
    }, [])

    useEffect(() => {
        const temp = response?.data?.data;
        if (temp) {
            temp?.map((val) => {
   
                // val.name = val.customerData.name;
                // val.membership = val.customerData.membership?.membershipType;
                // val.expiredValue = val.walletData.wallet.expiredValue;
                // val.customerid = val.customerData.customerid;
                // val.email = val.customerData.email;
                // val.mobile = val.customerData.mobile;
                val.earnedValue = val.walletData.wallet.earnedValue;
                val.availableValue = val.walletData.wallet.availableValue;
                val.redeemValue = val.walletData.wallet.redeemValue;
                val.action = val.customerData.customercode;
    
                if (shemaData) {
                    Object.keys(shemaData).forEach(key => {
                        if (shemaData[key].isFormView) {
                            val[key] = val.customerData[key] || '';
                        }
                    });
                }
    
                return val;
            });
            setData(temp);
        }
    }, [response, shemaData]); 

    const fetch = async () => {
        setLoader(true);
        await clearCustomer();
        const token = JSON.parse(localStorage.getItem("token_gen"));
        let id = localStorage.getItem("Id");
        await getCustomer(token?.access_token, id, 1, 50, " ");
        setTimeout(() => {
            setLoader(false);
        }, 1000);
    };

    const generateCSV = () => {
        try {
            if (!data || data.length === 0) {
                alert("No customer data available to download!");
                return;
            }
            const downloadColumns = columns.filter(col => col.accessor !== "action");
            const headers = downloadColumns.map(col => col.Header);
            const rows = data.map(item => {
                return downloadColumns.map(col => {
                    const value = item[col.accessor];
                    if (value === 0) return "0";  
                    if (value === null || value === undefined) return "";
                    return value.toString(); 
                });
            });
            const csvContent = [headers, ...rows]
                .map(row => row.join(","))
                .join("\n");
            const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "customers_data.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("CSV Download Error:", error);
            alert("Failed to generate CSV. Please try again.");
        }
    };

    useEffect(() => {
        setCustomerCount(Customer?.data?.data?.totalCount);
    }, [Customer]);
    const dynamicColumns = useMemo(() => {
        if (!shemaData) return [];
        
        return Object.entries(shemaData)
            .filter(([key, value]) => value.isFormView)
            .map(([key, value]) => ({
                Header: value.fielddisplayname || value.label || key,
                Footer: value.fielddisplayname || value.label || key,
                accessor: key,
            }));
    }, [shemaData]);
    const columns = useMemo(() => {
        const staticColumns = [
            ...dynamicColumns,  
          
           
           
            {
                Header: "Earned Value",
                Footer: "Earned Value",
                accessor: "earnedValue",
            },
            {
                Header: "Available Value",
                Footer: "Available Value",
                accessor: "availableValue",
            },
            {
                Header: "Redeem Value",
                Footer: "Redeem Value",
                accessor: "redeemValue",
            },
       
            {
                Header: "Action",
                Footer: "Action",
                accessor: "action",
                Cell: ({ value }) => (
                    <Link
                        to={{
                            pathname: `/customer-view/${value}`,
                            state: { customerCode: value },
                        }}
                    >
                        <i className="fa-solid fa-eye"></i> View Details
                    </Link>
                ),
            },
        ];
        return staticColumns;
    }, [dynamicColumns]);

    return (
        <>
            <Header name={"Customer"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__transactionsCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4>Customer Data</h4>
                            </div>
                            <div className="dashboard__right">
                                <Link to="/uploading-existing-datas" id="upload_customer" state={{ from: "customer" }} className="btn btn-default">
                                    <img src={importIcon} alt="" /> Upload Customer
                                </Link>
                            </div>
                        </div>
                        <div className="dashboard__body">
                            <p>No existing customer data. </p>
                            <p>You can add data by uploading a CSV file containing the details. </p>
                        </div>
                    </div>
                    <div className="dashboard__customersCard">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4>Customer List</h4>
                            </div>
                            <div className="dashboard__right">
                                <button onClick={generateCSV} className="btn btn-default">
                                    <i className="fa-solid fa-download" style={{marginRight:"10px"}}></i>Download Customer
                                </button>
                            </div>
                        </div>
                        <div className="dashboard__body">
                            <Paginated 
                                data={data} 
                                columns={columns} 
                                loader={loader} 
                                setLoader={setLoader} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

const mapStateToProps = (state) => ({
    Customer: state.Customer,
});
const mapDispatchToProps = {
    getCustomer,
    clearCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersList);
