export const COLUMNS = [
    {
        Header: "Name",
        Footer: "Name",
        accessor: "name",
    },
    {
        Header: "Code",
        Footer: "Code",
        accessor: "code",
    },
    {
        Header: "Priority",
        Footer: "Priority",
        accessor: "priority",
    },
    {
        Header: "Status",
        Footer: "Status",
        accessor: "status",
    },
   
    {
        Header: "Action",
        Footer: "Action",
        accessor: "action",
    },
];
