import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

const BarChartAnalyticalReportEmployee = ({ data }) => {
    const [Data, setData] = useState({
        labels: [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ],
        datasets: [
            {
                label: "Transaction Count (in thousands)",
                data: Array(12).fill(0),
                fill: true,
                tension: 0.4,
                backgroundColor: "#9A00C0",
            },
        ],
    });

    useEffect(() => {
        if (data && Array.isArray(data)) {
            const transformedData = data.map(value => (Number(value) / 1000).toFixed(0));
            setData({ ...Data, datasets: [{ ...Data.datasets[0], data: transformedData }] });
        }
    }, [data]);

    const options = {
        indexAxis: "x",
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <>
            <div className="analyticalReportChartAnalysisOne__barChartReport">
                <Bar data={Data} options={options} />
            </div>
        </>
    );
};

export default BarChartAnalyticalReportEmployee;
