import { decrypt } from "../../../utils/encodingdecoding";

export const COLUMNS = [
    // {
    //     Header: "Customer Code",
    //     Footer: "Customer Code",
    //     accessor: "customercode",
    //     disableFilters: true,
    //     sticky: "left",
    // },
    {
        Header: "Transaction ID",
        Footer: "Transaction ID",
        accessor: "transactionId",
        sticky: "left",
    },
    {
        Header: "IsQualified",
        Footer: "IsQualified",
        accessor: "isQualified",
        sticky: "left",
    },
    {
        Header: "Transaction Type",
        Footer: "Transaction Type",
        accessor: "transactionType",
        sticky: "left",
    },
    {
        Header: "Earning",
        Footer: "Earning",
        accessor: "earning",
        sticky: "left",
    },

    {
        Header: decrypt(localStorage.getItem("Id")) === "MER010004" ? "Reward" : "Total Amount",
        Footer: decrypt(localStorage.getItem("Id")) === "MER010004" ? "Reward" : "Total Amount",
        accessor: decrypt(localStorage.getItem("Id")) === "MER010004" ? "rewardId" : "totalAmount",
    },
    {
        Header: "Date",
        Footer: "Date",
        accessor: "transactionProcessedDate",
        sticky: "left",
        // Cell: ({ value }) => {
        // 	return format(new Date(value), "dd/MM/yyyy");
        // },
    },
    {
        Header: "Action",
        Footer: "Action",
        accessor: "action",
    },
];

