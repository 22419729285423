
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../layout/Header";
import { getPerformanceReport } from "../campaigns/services";
import SelectValidator from "../../partial/SelectValidator";
import { ValidatorForm } from "react-form-validator-core";
import { getCampaigns, clearCampaigns } from "../../../actions/campaigns";
import { connect } from "react-redux";
import { getDate, selectValueGetter } from "../../../utils/helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Report = ({ getCampaigns, Campaigns }) => {

    const location = useLocation();
    const [loader, setLoader] = useState(false);
    const [isActive2, setIsActive2] = useState(false);
    const [isActive1, setIsActive1] = useState(false);
    const [dataFound, setDataFound] = useState(true);
    const [dataFoundDays, setDataFoundDays] = useState(false);
    const campaignId = location?.state?.campaignId;
    const [option, setOption] = useState([]);
    const [options] = useState([
        { label: "7 days", value: "7" },
        { label: "15 days", value: "15" },
        { label: "1 month", value: "30" },
        { label: "3 months", value: "90" },
        { label: "6 months", value: "180" },
        { label: "1 year", value: "365" },
    ]);
    const initialState = {
        startDate: undefined,
        selectedMonthData: "",
        firstDate: "",
        lastDate: "",
        selectedMonth: "",
        selectedByDate: undefined,
        selectedByMonthDate: undefined,
        selectedYear: "",
        selectedYearData: "",
        selectedFromDays: "",
        selectedFromMonths: "",
        selectedFromDay: "",
        selectedFromMonth: "",
        response: [],
        responseDataWise: [],
        responseData: [],
        responseDataMonthWise: [],
        responseDataMonth: [],
        categoryData: "",
        selectedData: "",
        selectedDataComapre: "",
    };
    const [state, setState] = useState(initialState);

    useEffect(() => {
        const campaignData = async () => {
            if (campaignId) {
                setLoader(true)
                const token = JSON.parse(localStorage.getItem("token_gen"));
                const id = localStorage.getItem("Id");
                try {
                    let responseCustomer = await getPerformanceReport(token?.access_token, id, campaignId);
                    setState((prevState) => ({
                        ...prevState,
                        response: responseCustomer?.data,
                        firstDate: responseCustomer?.data[0]?.data?.firstTransactionDate,
                        lastDate: responseCustomer?.data[0]?.data?.lastTransactionDate,
                    }));
                    setLoader(false)
                } catch (error) {
                }
            }
        };
        campaignData();
    }, []);

    const performanceReport = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = localStorage.getItem("Id");
        let responseCustomer = await getPerformanceReport(token?.access_token, id);
        setState((prevState) => ({
            ...prevState,
            response: responseCustomer?.data,
            firstDate: responseCustomer?.data[0]?.data?.firstTransactionDate,
            lastDate: responseCustomer?.data[0]?.data?.lastTransactionDate,
        }));
        setLoader(false);
    };

    const onSubmit = async () => {
        setDataFound(true)
        setState((prevState) => ({
            ...prevState,
            categoryData: "",
            selectedData: "",
            selectedByDate: "",
            startDate: "",
            selectedByMonthDate: "",
            responseData: "",
            responseDataWise: "",
            responseDataMonthWise: "",
            responseDataMonth: "",
            selectedDataComapre: "",
        }));
        setDataFoundDays(false)
        await performanceReport();
    };

    const fetch = async () => {
        setLoader(true);
        clearCampaigns()
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = localStorage.getItem("Id");
        await getCampaigns(token?.access_token, id);
        setLoader(false);
    };

    const fetchCampaigns = async () => {
        let temp = [];
        let flag = 1;
        for (let i = 0; i < Campaigns?.data?.length; i++) {
            temp.push({
                label: Campaigns?.data[i]?.name,
                value: Campaigns?.data[i]?.code,
                index: flag,
            });
            flag += 1;
        }
        setOption(temp);
    };

    useEffect(() => {
        fetchCampaigns();
    }, [Campaigns]);

    useEffect(() => {
        const selectedCampaign = option?.find((campaign) => campaign?.value === campaignId);
        setState((prevState) => ({ ...prevState, categoryData: selectedCampaign?.value }));
    }, [campaignId, option]);

    const handleSelect = (value) => {
        setState((prevState) => ({
            ...prevState,
            categoryData: value,
            startDate: "",
            selectedDataComapre: "",
            selectedByMonthDate: "",
            selectedByDate: ""
        }));
    };

    const handleSelectDate = (value) => {
        setState((prevState) => ({
            ...prevState,
            selectedData: value,
            startDate: "",
            selectedDataComapre: "",
            selectedByMonthDate: "",
            selectedByDate: "",
        }));
    };

    const handleSelectByMonth = (date) => {
        setState((prevState) => ({
            ...prevState,
            startDate: date,
            selectedDataComapre: "",
            selectedByMonthDate: "",
            selectedByDate: "",
            categoryData: "",
            selectedData: ""
        }));
    };

    const handleSubmit = async (date) => {
        setLoader(true)
        setIsActive1(!isActive1);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = localStorage.getItem("Id");
        try {
            if (state?.categoryData && !state?.selectedData) {
                setDataFound(true)
                setDataFoundDays(false)
                setState((prevState) => ({
                    ...prevState,
                    responseDataWise: "",
                    responseDataMonthWise: "",
                    startDate: null,
                    responseData: "",
                    responseDataMonth: "",
                    selectedDataComapre: "",
                }));
                let responseCustomer = await getPerformanceReport(token?.access_token, id, state?.categoryData);
                setState((prevState) => ({ ...prevState, response: responseCustomer?.data }));
                setLoader(false)
            } else if (state?.selectedData && !state?.categoryData) {
                setDataFound(true)
                setDataFoundDays(false)
                setState((prevState) => ({
                    ...prevState,
                    responseDataWise: "",
                    responseDataMonthWise: "",
                    startDate: null,
                    responseData: "",
                    responseDataMonth: "",
                    selectedDataComapre: "",
                }));
                let toDateObj = new Date(state?.lastDate);
                const todate = toDateObj.toISOString().split("T")[0];
                let fromDateObj = new Date(todate);
                switch (state?.selectedData) {
                    case "7":
                        fromDateObj.setDate(fromDateObj.getDate() - 6);
                        break;
                    case "15":
                        fromDateObj.setDate(fromDateObj.getDate() - 14);
                        break;
                    case "30":
                        fromDateObj.setMonth(fromDateObj.getMonth() - 1);
                        break;
                    case "90":
                        fromDateObj.setMonth(fromDateObj.getMonth() - 3);
                        break;
                    case "180":
                        fromDateObj.setMonth(fromDateObj.getMonth() - 6);
                        break;
                    case "365":
                        fromDateObj.setFullYear(fromDateObj.getFullYear() - 1);
                        break;
                    default:
                        break;
                }
                const fromdate = fromDateObj.toISOString().split("T")[0];
                let responseCustomer = await getPerformanceReport(token?.access_token, id, null, fromdate, todate);
                setState((prevState) => ({ ...prevState, response: responseCustomer?.data }));
                setLoader(false)
            } else if (state?.categoryData && state?.selectedData) {
                setDataFound(true)
                setDataFoundDays(false)
                setState((prevState) => ({
                    ...prevState,
                    responseDataWise: "",
                    responseDataMonthWise: "",
                    startDate: null,
                    responseData: "",
                    responseDataMonth: "",
                    selectedDataComapre: "",
                }));
                let toDateObj = new Date(state?.lastDate);
                const todate = toDateObj.toISOString().split("T")[0];
                let fromDateObj = new Date(todate);
                switch (state?.selectedData) {
                    case "7":
                        fromDateObj.setDate(fromDateObj.getDate() - 6);
                        break;
                    case "15":
                        fromDateObj.setDate(fromDateObj.getDate() - 14);
                        break;
                    case "30":
                        fromDateObj.setMonth(fromDateObj.getMonth() - 1);
                        break;
                    case "90":
                        fromDateObj.setMonth(fromDateObj.getMonth() - 3);
                        break;
                    case "180":
                        fromDateObj.setMonth(fromDateObj.getMonth() - 6);
                        break;
                    case "365":
                        fromDateObj.setFullYear(fromDateObj.getFullYear() - 1);
                        break;
                    default:
                        break;
                }
                const fromdate = fromDateObj.toISOString().split("T")[0];
                let responseCustomer = await getPerformanceReport(token?.access_token, id, state?.categoryData, fromdate, todate);
                setState((prevState) => ({ ...prevState, response: responseCustomer?.data }));
                setLoader(false)
            } else if (state?.startDate) {
                setDataFound(true)
                setDataFoundDays(false)
                setState((prevState) => ({
                    ...prevState,
                    categoryData: "",
                    selectedData: "",
                    responseDataMonth: "",
                    selectedDataComapre: "",
                }));
                const starrrdate = state?.startDate.toISOString().split("T")[0];
                const starrrdateObj = new Date(starrrdate);
                starrrdateObj.setDate(starrrdateObj.getDate() + 1);
                const nextDayISO = starrrdateObj.toISOString().split("T")[0];
                const endDate = new Date(state?.startDate.getFullYear(), state?.startDate.getMonth() + 1);
                const starrr = endDate.toISOString().split("T")[0];
                let responseCustomer = await getPerformanceReport(token?.access_token, id, null, nextDayISO, starrr);
                setState((prevState) => ({ ...prevState, response: responseCustomer?.data }));
                setLoader(false)
            }
        } catch (error) {
        }
        setLoader(false)
    };

    const handleSelectByDate = (value) => {
        setState((prevState) => ({
            ...prevState,
            selectedDataComapre: value,
            categoryData: "",
            selectedData: "",
            selectedByDate: "",
            startDate: "",
            selectedByMonthDate: "",
        }));
    };

    const handleSelectedByDate = (date) => {
        setState((prevState) => ({
            ...prevState,
            selectedByDate: date,
            categoryData: "",
            selectedData: "",
            startDate: "",
            selectedDataComapre: "",
        }));
    };

    const handleSelectedBYMonth = (date) => {
        setState((prevState) => ({
            ...prevState,
            selectedByMonthDate: date,
            categoryData: "",
            selectedData: "",
            startDate: "",
            selectedDataComapre: "",
        }));
    };

    const handleMonthSubmit = async () => {
        setLoader(true);
        setIsActive2(!isActive2);
        if (state?.selectedDataComapre) {
            setDataFoundDays(true)
            setDataFound(null)
            setState((prevState) => ({
                ...prevState,
                selectedByDate: "",
                response: "",
                selectedData: "",
                responseDataMonth: "",
                responseData: "",
                startDate: "",
                categoryData: "",
                selectedMonth: "",
                selectedYear: "",
                selectedMonthData: "",
                selectedYearData: ""
            }));
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const id = localStorage.getItem("Id");
            try {
                let toDateObj = new Date(state?.lastDate);
                const todate = toDateObj.toISOString().split("T")[0];
                let fromDateObj = new Date(todate);
                switch (state?.selectedDataComapre) {
                    case "7":
                        fromDateObj.setDate(fromDateObj.getDate() - 6);
                        break;
                    case "15":
                        fromDateObj.setDate(fromDateObj.getDate() - 14);
                        break;
                    case "30":
                        fromDateObj.setMonth(fromDateObj.getMonth() - 1);
                        break;
                    case "90":
                        fromDateObj.setMonth(fromDateObj.getMonth() - 3);
                        break;
                    case "180":
                        fromDateObj.setMonth(fromDateObj.getMonth() - 6);
                        break;
                    case "365":
                        fromDateObj.setFullYear(fromDateObj.getFullYear() - 1);
                        break;
                    default:
                        break;
                }
                const fromdate = fromDateObj.toISOString().split("T")[0];
                var dateObjects = getDate(fromdate, "DD-MMM-YYYY");
                var dateObject = getDate(todate, "DD-MMM-YYYY");
                let responseCustomer = await getPerformanceReport(token?.access_token, id, null, fromdate, todate);
                setState((prevState) => ({
                    ...prevState,
                    responseDataWise: responseCustomer?.data,
                    selectedFromDays: dateObjects,
                    selectedFromMonths: dateObject,
                }));
                const adjustedFromDate = new Date(fromDateObj);
                adjustedFromDate.setDate(adjustedFromDate.getDate() - 1);
                const adjustedFromdate = adjustedFromDate.toISOString().split("T")[0];
                let fromDateObjdata = new Date(adjustedFromdate);
                switch (state?.selectedDataComapre) {
                    case "7":
                        fromDateObjdata.setDate(fromDateObjdata.getDate() - 6);
                        break;
                    case "15":
                        fromDateObjdata.setDate(fromDateObjdata.getDate() - 14);
                        break;
                    case "30":
                        fromDateObjdata.setMonth(fromDateObjdata.getMonth() - 1);
                        break;
                    case "90":
                        fromDateObjdata.setMonth(fromDateObjdata.getMonth() - 3);
                        break;
                    case "180":
                        fromDateObjdata.setMonth(fromDateObjdata.getMonth() - 6);
                        break;
                    case "365":
                        fromDateObjdata.setFullYear(fromDateObjdata.getFullYear() - 1);
                        break;
                    default:
                        break;
                }
                const adjustedTodate = fromDateObjdata.toISOString().split("T")[0];
                var monthObjects = getDate(adjustedTodate, "DD-MMM-YYYY");
                var monthObject = getDate(adjustedFromdate, "DD-MMM-YYYY");
                let responseCustomerSecondCall = await getPerformanceReport(token?.access_token, id, null, adjustedTodate, adjustedFromdate);
                setState((prevState) => ({
                    ...prevState,
                    responseDataMonthWise: responseCustomerSecondCall.data,
                    selectedFromDay: monthObjects,
                    selectedFromMonth: monthObject,
                }));
                setLoader(false);
            } catch (error) {
            }
        } else if (state?.selectedByDate && !state?.selectedByMonthDate) {
            setDataFoundDays(false)
            setDataFound(false)
            setState((prevState) => ({
                ...prevState,
                selectedDataComapre: "",
                startDate: "",
                categoryData: "",
                response: "",
                selectedData: "",
                responseDataMonthWise: "",
                responseDataWise: "",
            }));
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const id = localStorage.getItem("Id");
            try {
                const starrrdate = state?.selectedByDate.toISOString().split("T")[0];
                const starrrdateObj = new Date(starrrdate);
                starrrdateObj.setDate(starrrdateObj.getDate() + 1);
                const nextDayISO = starrrdateObj.toISOString().split("T")[0];
                const endDate = new Date(state?.selectedByDate.getFullYear(), state?.selectedByDate.getMonth() + 1);
                const starrr = endDate.toISOString().split("T")[0];
                let responseCustomer = await getPerformanceReport(token?.access_token, id, null, nextDayISO, starrr);
                setState((prevState) => ({
                    ...prevState,
                    responseDataMonth: responseCustomer?.data,
                    selectedMonth: state?.selectedByDate.toLocaleString("default", { month: "short" }),
                    selectedYear: state?.selectedByDate.getFullYear(),
                }));
                setLoader(false);
            } catch (error) {
            }
        } else if (state?.selectedByMonthDate && !state?.selectedByDate) {
            setDataFound(false);
            setDataFoundDays(false);
            setState((prevState) => ({
                ...prevState,
                selectedDataComapre: "",
                startDate: "",
                categoryData: "",
                response: "",
                selectedData: "",
                responseDataMonthWise: "",
                responseDataWise: "",
            }));
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const id = localStorage.getItem("Id");
            try {
                const starrrdate = state?.selectedByMonthDate.toISOString().split("T")[0];
                const starrrdateObj = new Date(starrrdate);
                starrrdateObj.setDate(starrrdateObj.getDate() + 1);
                const nextDayISO = starrrdateObj.toISOString().split("T")[0];
                const endDate = new Date(state?.selectedByMonthDate.getFullYear(), state?.selectedByMonthDate.getMonth() + 1);
                const starrr = endDate.toISOString().split("T")[0];
                let responseCustomer = await getPerformanceReport(token?.access_token, id, null, nextDayISO, starrr);
                setState((prevState) => ({
                    ...prevState,
                    responseData: responseCustomer?.data,
                    selectedMonthData: state?.selectedByMonthDate.toLocaleString("default", { month: "short" }),
                    selectedYearData: state?.selectedByMonthDate.getFullYear(),
                }));
                setLoader(false);
            } catch (error) {
            }
        } else if (state?.selectedByDate && state?.selectedByMonthDate) {
            setDataFound(false);
            setDataFoundDays(false);
            setState((prevState) => ({
                ...prevState,
                selectedDataComapre: "",
                startDate: "",
                categoryData: "",
                response: "",
                selectedData: "",
                responseDataMonthWise: "",
                responseDataWise: "",
            }));

            const token = JSON.parse(localStorage.getItem("token_gen"));
            const id = localStorage.getItem("Id");
            try {
                const starrrdate = state?.selectedByDate.toISOString().split("T")[0];
                const starrrdateObj = new Date(starrrdate);
                starrrdateObj.setDate(starrrdateObj.getDate() + 1);
                const nextDayISO = starrrdateObj.toISOString().split("T")[0];
                const endDate = new Date(state?.selectedByDate.getFullYear(), state?.selectedByDate.getMonth() + 1);
                const starrr = endDate.toISOString().split("T")[0];
                let responseCustomer = await getPerformanceReport(token?.access_token, id, null, nextDayISO, starrr);
                setState((prevState) => ({
                    ...prevState,
                    responseDataMonth: responseCustomer?.data,
                    selectedMonth: state?.selectedByDate.toLocaleString("default", { month: "short" }),
                    selectedYear: state?.selectedByDate.getFullYear(),
                }));
                const starrrdatee = state?.selectedByMonthDate.toISOString().split("T")[0];
                const starrrdateObjj = new Date(starrrdatee);
                starrrdateObjj.setDate(starrrdateObjj.getDate() + 1);
                const nextDayISOO = starrrdateObjj.toISOString().split("T")[0];
                const endDatee = new Date(state?.selectedByMonthDate.getFullYear(), state?.selectedByMonthDate.getMonth() + 1);
                const starrrr = endDatee.toISOString().split("T")[0];
                let responseCustomerSecond = await getPerformanceReport(token?.access_token, id, null, nextDayISOO, starrrr);
                setState((prevState) => ({
                    ...prevState,
                    responseData: responseCustomerSecond?.data,
                    selectedMonthData: state?.selectedByMonthDate.toLocaleString("default", { month: "short" }),
                    selectedYearData: state?.selectedByMonthDate.getFullYear(),
                }));
                setLoader(false);
            } catch (error) {
            }
        }
        setLoader(false)
    };

    useEffect(() => {
        (async () => {
            if (!campaignId) {
                await performanceReport();
            }
            await fetch();
            setLoader(false);
        })();
    }, []);


    const handleHeaderClick1 = () => {
        setIsActive1(!isActive1);
        setIsActive2(false);
    };
    const handleHeaderClick2 = () => {
        setIsActive2(!isActive2);
        setIsActive1(false);;
    };

    return (
        <>
            <Header name={"Report"} />
            {loader && (
                <div className="mainPreloaderMain">
                    <div className="mainPreloader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            <div className="dashboard__content">
                <div className="container-fluid">
                    <div className="dashboard__customersCard dashboard__customersCard_report">
                        <div className="dashboard__header">
                            <div className="dashboard__left">
                                <h4 id="customer_list">Report</h4>
                            </div>
                            {state?.firstDate && state?.lastDate ? (
                                <div className="dashboard__left">
                                    <b id="customer_list" style={{ color: "white" }}>Transaction date : {getDate(state?.firstDate, "DD-MMM-YYYY")} to {getDate(state?.lastDate, "DD-MMM-YYYY")}</b>
                                </div>
                            ) : (
                                null
                            )}
                        </div>
                        <div className="dashboard__body">
                            <div className="form-group">
                                <ValidatorForm onSubmit={onSubmit}>
                                    <div className="cs_report_mainbox">
                                        <div className="cs_report_box">
                                            <div className="cs_report_box_header" onClick={handleHeaderClick1}>
                                                Report
                                            </div>
                                            <div className={`cs_reports_mainbox ${isActive1 ? "active" : ""}`}>
                                                <div className="cs_report_box_results">
                                                    <div className="cs_report_space">
                                                        <label>Campaigns</label>
                                                        <SelectValidator placeholder="Select campaigns" className="" name="choice" value={state?.categoryData ? selectValueGetter(option, state?.categoryData) : []} selected={state?.categoryData} onChange={(e) => handleSelect(e.value)} options={option} />
                                                    </div>
                                                    <div className="cs_report_space">
                                                        <label>Report for last</label>
                                                        <SelectValidator placeholder="Select Days" className="" name="choice" value={state?.selectedData ? selectValueGetter(options, state?.selectedData) : []} selected={state?.selectedData} onChange={(e) => handleSelectDate(e.value)} options={options} />
                                                    </div>
                                                    <div className="cs_report_space">
                                                        <label>Month Wise Report</label>
                                                        <DatePicker selected={state?.startDate} onChange={(date) => handleSelectByMonth(date)} dateFormat="MMM-yyyy" showMonthYearPicker placeholderText="Select a month" />
                                                    </div>
                                                    <div className="cs_report_btns">
                                                        <div className="reseter_btn">
                                                            <button type="button" onClick={handleSubmit}>
                                                                Submit
                                                            </button>
                                                        </div>
                                                        <div className="reseter_btn reseter_btn_small">
                                                            <button>Reset</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cs_report_box">
                                            <div className="cs_report_box_header" onClick={handleHeaderClick2}>
                                                Compare Report
                                            </div>
                                            <div className={`cs_reports_mainbox ${isActive2 ? "active" : ""}`}>
                                                <div className="cs_report_box_results">
                                                    <div className="cs_report_space">
                                                        <label>Compare Report of last</label>
                                                        <SelectValidator placeholder="Select Days" className="" name="choice" value={state?.selectedDataComapre ? selectValueGetter(options, state?.selectedDataComapre) : []} selected={state?.selectedDataComapre} onChange={(e) => handleSelectByDate(e.value)} options={options} />
                                                    </div>
                                                    <div className="cs_report_space">
                                                        <label>Month</label>
                                                        <DatePicker selected={state?.selectedByDate} onChange={(date) => handleSelectedByDate(date)} dateFormat="MMM-yyyy" showMonthYearPicker placeholderText="Select a month" />
                                                    </div>
                                                    <div className="cs_report_space">
                                                        <label>To Month </label>
                                                        <DatePicker selected={state?.selectedByMonthDate} onChange={(date) => handleSelectedBYMonth(date)} dateFormat="MMM-yyyy" showMonthYearPicker placeholderText="Select a month" />
                                                    </div>
                                                    <div className="cs_report_btns">
                                                        <div className="reseter_btn">
                                                            <button type="button" onClick={handleMonthSubmit}>
                                                                Submit
                                                            </button>
                                                        </div>
                                                        <div className="reseter_btn reseter_btn_small">
                                                            <button>Reset</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ValidatorForm>
                            </div>

                            {dataFoundDays === true ? (
                                state?.responseDataWise?.length > 0 && state?.responseDataMonthWise?.length > 0 ? (
                                    <div className="table-responsive" id="responseDataMonth_Report">
                                        <table className="table table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "left", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>Members</th>
                                                    <th style={{ textAlign: "center", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>{`${state?.selectedFromMonths} to ${state?.selectedFromDays}`}</th>
                                                    <th style={{ textAlign: "center", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>{`${state?.selectedFromMonth} to ${state?.selectedFromDay}`}</th>
                                                </tr>
                                            </thead>
                                        
                                            <tbody>
                                                
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Number of Customers</td>
                                                    <td>{state?.responseDataWise[0]?.data?.no_of_Customer || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[0]?.data?.no_of_Customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Order Value</td>
                                                    <td>{state?.responseDataWise[0]?.data?.avg_Order_Value || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[0]?.data?.avg_Order_Value || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Number of Orders per Customer</td>
                                                    <td>{state?.responseDataWise[0]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[0]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Spend per Customer</td>
                                                    <td>{state?.responseDataWise[0]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[0]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                </tr>
                                            </tbody>
                                            <thead>
                                                <tr>
                                                    <th colSpan={3} style={{ textAlign: "left", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>
                                                        Non-Members
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Number of Customers</td>
                                                    <td>{state?.responseDataWise[1]?.data?.no_of_Customer || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[1]?.data?.no_of_Customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ float: "left" }}>Average Order Value</td>
                                                    <td>{state?.responseDataWise[1]?.data?.avg_Order_Value || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[1]?.data?.avg_Order_Value || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ float: "left" }}>Average Number of Orders per Customer</td>
                                                    <td>{state?.responseDataWise[1]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[1]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ float: "left" }}>Average spend per customer</td>
                                                    <td>{state?.responseDataWise[1]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[1]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                </tr>
                                            </tbody>
                                            <thead>
                                                <tr>
                                                    <th colSpan={3} style={{ textAlign: "left", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>
                                                        Redeemed Members
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Number of Customers</td>
                                                    <td>{state?.responseDataWise[2]?.data?.no_of_Customer || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[2]?.data?.no_of_Customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Order Value</td>
                                                    <td>{state?.responseDataWise[2]?.data?.avg_Order_Value || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[2]?.data?.avg_Order_Value || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Number of Orders per Customer</td>
                                                    <td>{state?.responseDataWise[2]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[2]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Spend per Customer</td>
                                                    <td>{state?.responseDataWise[2]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[2]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                </tr>
                                            </tbody>
                                            <thead>
                                                <tr>
                                                    <th colSpan={3} style={{ textAlign: "left", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>
                                                        Non Redeemed Members
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Number of Customers</td>
                                                    <td>{state?.responseDataWise[3]?.data?.no_of_Customer || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[3]?.data?.no_of_Customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Order Value</td>
                                                    <td>{state?.responseDataWise[3]?.data?.avg_Order_Value || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[3]?.data?.avg_Order_Value || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Number of Orders per Customer</td>
                                                    <td>{state?.responseDataWise[3]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[3]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Spend per Customer</td>
                                                    <td>{state?.responseDataWise[3]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                    <td>{state?.responseDataMonthWise[3]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                   
                                    </div>
                                ) : (
                                    <div className="table-responsive" id="responseDataMonth_Report">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "left", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>Members</th>
                                                    <th style={{ textAlign: "center", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>{` ${state?.selectedMonth} ${state?.selectedYear}`}</th>
                                                    <th style={{ textAlign: "center", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>{`${state?.selectedMonthData} ${state?.selectedYearData}`}</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td colSpan={9}>
                                                        <p style={{ textAlign: "center", margin: 0 }}>No data found</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            ) : null}
                            {dataFound === false ? (
                                state?.responseDataMonth?.length > 0 || state?.responseData?.length > 0 ? (
                                    <div className="table-responsive" id="responseDataMonth_Report">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "left", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>Members</th>
                                                    <th style={{ textAlign: "center", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>{`${state?.selectedMonth} ${state?.selectedYear}`}</th>
                                                    <th style={{ textAlign: "center", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>{`${state?.selectedMonthData} ${state?.selectedYearData}`}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Number of Customers</td>
                                                    <td>{state?.responseDataMonth[0]?.data?.no_of_Customer || "0"}</td>
                                                    <td>{state?.responseData[0]?.data?.no_of_Customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Order Value</td>
                                                    <td>{state?.responseDataMonth[0]?.data?.avg_Order_Value || "0"}</td>
                                                    <td>{state?.responseData[0]?.data?.avg_Order_Value || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Number of Orders per Customer</td>
                                                    <td>{state?.responseDataMonth[0]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                    <td>{state?.responseData[0]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Spend per Customer</td>
                                                    <td>{state?.responseDataMonth[0]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                    <td>{state?.responseData[0]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                </tr>
                                            </tbody>
                                            <thead>
                                                <tr>
                                                    <th colSpan={3} style={{ textAlign: "left", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>
                                                        Non-Members
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Number of Customers</td>
                                                    <td>{state?.responseDataMonth[1]?.data?.no_of_Customer || "0"}</td>
                                                    <td>{state?.responseData[1]?.data?.no_of_Customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Order Value</td>
                                                    <td>{state?.responseDataMonth[1]?.data?.avg_Order_Value || "0"}</td>
                                                    <td>{state?.responseData[1]?.data?.avg_Order_Value || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Number of Orders per Customer</td>
                                                    <td>{state?.responseDataMonth[1]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                    <td>{state?.responseData[1]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Spend per Customer</td>
                                                    <td>{state?.responseDataMonth[1]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                    <td>{state?.responseData[1]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                </tr>
                                            </tbody>
                                            <thead>
                                                <tr>
                                                    <th colSpan={3} style={{ textAlign: "left", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>
                                                        Redeemed Members
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Number of Customers</td>
                                                    <td>{state?.responseDataMonth[2]?.data?.no_of_Customer || "0"}</td>
                                                    <td>{state?.responseData[2]?.data?.no_of_Customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Order Value</td>
                                                    <td>{state?.responseDataMonth[2]?.data?.avg_Order_Value || "0"}</td>
                                                    <td>{state?.responseData[2]?.data?.avg_Order_Value || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Number of Orders per Customer</td>
                                                    <td>{state?.responseDataMonth[2]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                    <td>{state?.responseData[2]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Spend per Customer</td>
                                                    <td>{state?.responseDataMonth[2]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                    <td>{state?.responseData[2]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                </tr>
                                            </tbody>
                                            <thead>
                                                <tr>
                                                    <th colSpan={3} style={{ textAlign: "left", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>
                                                        Non Redeemed Members
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Number of Customers</td>
                                                    <td>{state?.responseDataMonth[3]?.data?.no_of_Customer || "0"}</td>
                                                    <td>{state?.responseData[3]?.data?.no_of_Customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Order Value</td>
                                                    <td>{state?.responseDataMonth[3]?.data?.avg_Order_Value || "0"}</td>
                                                    <td>{state?.responseData[3]?.data?.avg_Order_Value || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Number of Orders per Customer</td>
                                                    <td>{state?.responseDataMonth[3]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                    <td>{state?.responseData[3]?.data?.avg_no_of_order_per_customer || "0"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Average Spend per Customer</td>
                                                    <td>{state?.responseDataMonth[3]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                    <td>{state?.responseData[3]?.data?.avg_Spend_per_Customer || "0"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div className="table-responsive" id="responseDataMonth_Report">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "left", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>Members</th>
                                                    <th style={{ textAlign: "center", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>{` ${state?.selectedMonth} ${state?.selectedYear}`}</th>
                                                    <th style={{ textAlign: "center", backgroundColor: "#ADD8E6", color: "#1B1B1B", padding: "1rem !important" }}>{` ${state?.selectedMonthData} ${state?.selectedYearData}`}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={9}>
                                                        <p style={{ textAlign: "center", margin: 0 }}>No data found</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            ) : null}
                            {dataFound === true ? (
                                state?.response?.length > 0 ? (
                                    <div className="table-responsive" id="transaction_Report">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Loyalty Status</th>
                                                    <th>Number of Customers</th>
                                                    <th>Average Order Value</th>
                                                    <th>Average Number of Orders per Customer</th>
                                                    <th>Average Spend per Customer </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {state?.response &&
                                                    state?.response.map((transaction, i) => (
                                                        <tr key={i}>
                                                            <td>
                                                                {transaction?.loyality_Status === "Members" && <>Members</>}
                                                                {transaction?.loyality_Status === "Non-Members" && <>Non-Members</>}
                                                                {transaction?.loyality_Status === "RedeemedMembers" && <>Redeemed Members</>}
                                                                {transaction?.loyality_Status === "NonRedeemedMembers" && <>Non Redeemed Members</>}
                                                            </td>
                                                            <td>{transaction?.data?.no_of_Customer}</td>
                                                            <td>{transaction?.data?.avg_Order_Value}</td>
                                                            <td>{transaction?.data?.avg_no_of_order_per_customer}</td>
                                                            <td>{transaction?.data?.avg_Spend_per_Customer}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div className="table-responsive" id="transaction_Report">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Loyalty Status</th>
                                                    <th>Number of Customers</th>
                                                    <th>Average Order Value</th>
                                                    <th>Average Number of Orders per Customer</th>
                                                    <th>Average Spend per Customer</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={9}>
                                                        <p style={{ textAlign: "center", margin: 0 }}>No data found</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    Campaigns: state.Campaigns,
});

const mapDispatchToProps = {
    getCampaigns,
    clearCampaigns,
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
